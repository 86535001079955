<section style="padding:  30px; min-height: calc(100vh - 50px ); background: none; margin-top:100px; background-size: cover ;">
	<div class="container">
		<div class="row">
			<div class="col-lg-6 mx-auto text-center">
				<h2>Meta Legend Community </h2>
				<p style="margin-bottom: 20px">Explore the world of Meta Legend by clicking & following us on social media channels.</p>
			</div>
		</div>
		<div class="row">
			<div class="col-lg-4 text-center">
				<div class="community-social">
					<img src="assets/images/facebook-icon.svg" width="100px">
					<a href="#" target="_blank" class="btn-community">Facebook</a>
				</div>
			</div>
			<div class="col-lg-4 text-center">
				<div class="community-social">
					<img src="assets/images/twitter-icon.svg" width="100px">
					<a href="#" target="_blank" class="btn-community">Twitter</a>
				</div>
			</div>
			<div class="col-lg-4 text-center">
				<div class="community-social">
					<img src="assets/images/medium-icon.svg" width="100px">
					<a href="#" target="_blank" class="btn-community">Medium</a>
				</div>
			</div>
			<div class="col-lg-4 ms-auto text-center">
				<div class="community-social">
					<img src="assets/images/discord-icon.svg" width="100px">
					<a href="#" target="_blank" class="btn-community">Discord</a>
				</div>
			</div>
			<div class="col-lg-4 me-auto text-center">
				<div class="community-social">
					<img src="assets/images/telegram-icon.svg" width="100px">
					<a href="#" target="_blank" class="btn-community">Telegram</a>
				</div>
			</div>
		</div>
		<div class="row" style="margin-top: 80px">
			<div class="col-lg-10 mx-auto">
				<div class="row">
					<div class="col-lg-3 text-center">
						<p style="color: #ffffff">©2024 Meta Legend</p>
					</div>
					<div class="col-lg-3 text-center">
						<a href="faq/privacy-policy" style="color: #ffffff">Privacy Policy</a>
					</div>
					<div class="col-lg-3 text-center">
						<a href="faq/terms-of-use" style="color: #ffffff">Term of Use</a>
					</div>
					<div class="col-lg-3 text-center">
						<a href="mailto:support@metalegend.ai" style="color: #ffffff">support@metalegend.ai</a>
					</div>
				</div>
			</div>
		</div>
	</div>

</section>

