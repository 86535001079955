<section style="padding:  30px; min-height: 100vh;  margin-top:90px;">
	<div class="container">
		<div class="row">
			<div class="col-lg-10 mx-auto">
				<h1>Terms of Service</h1>
			</div>
		</div>
		<div class="row">
			<div class="col-lg-10 mx-auto">
				<div class="mb-4">
					<h2>TERMS OF USE</h2>
					<h3>Subject matter</h3>
					<p><strong>META LEGEND</strong> is an online game to which gamers can buy Playable Animal NFTs. The game is still in development, therefore not yet released, and this may result in changes to the different Animals, capabilities and usage. Smart contracts grant the right to the specific Playable NFT purchased and only that.</p>
					<p>The game is still under development, therefore elements, features, characters can change things such as but not limited to visuals etc.</p>
				</div>

				<div class="mb-4">
					<h3>Definitions</h3>
					<p><strong>NFT :</strong> means Non-fungible token which can be bought on Polygon/Ethereum Network using cryptocurrency.</p>
					<p><strong>Smart Contract :</strong> means the contract you automatically agree to on the Polygon/Ethereum blockchain to execute transactions.</p>
				</div>

				<div class="mb-4">
					<h3>User registration and representation</h3>
					<p>You may be required to register and thereby submit your personal information to the Site and the Smart Contract. It is your responsibility to ensure complete, accurate, relevant information and to maintain the information associated with your registration and account.</p>
					<p>We inform you that you must have the legal capacity necessary to perform your obligations under these Terms and you understand that you cannot legally purchase Playable NFT, or use the App and the Site if you are a minor in the jurisdiction you reside.</p>
					<p>You agree to keep your wallet address and password personal and confidential and will be responsible for all use of and access to your account and password.</p>
					<p>You acknowledge that for the purpose of complying with applicable legislation, including but not limited to anti-money laundering laws and regulations, court rulings, public authorities decisions, industry practice, or other request or requirements, we may demand from you further information to be able to fully identify or perform checks of you or others; You agree and understand that fulfilling of such requirements may be necessary to complete the transactions on the Polygon/Ethreum Network with the purpose of buying, selling or trading Playable NFTs and using the Site, the App or the Smart Contract, and that we may have to rescind such transactions if you do not comply with our demands in this respect.</p>
				</div>
				
				<div class="mb-4">
					<h3>Our ownership</h3>
					<p>We own and shall remain the sole owner of all legal rights, titles and interests in and to all other elements of the Site and in the App, and all intellectual property rights (“IP rights”) vested in the App.</p>
				</div>
				
				<div class="mb-4">
					<h3>Your ownership</h3>
					<p>When purchasing Playable NFTs, you own the underlying NFT completely. Within your ownership right, you are permitted to trade, sell or give away your NFT. NFT ownership, and rights included herein are governed by the Smart Contract and the Polygon/Ethereum Network.</p>
				</div>

				<div class="mb-4">
					<h3>General use</h3>
					<p>We grant you a limited license to access and use the Site, the App and the Playable NFTs. The right granted is revocable, non-exclusive, non-perpetual and non-transferable except as otherwise set forth in these Terms. This right granted applies only to the extent that you continue to own the Playable NFTs. If you at any 9me decide to trade, sell or give away the Playable NFTs to others, the rights to the specific Playable NFTs immediately cease to exist.</p>
				</div>
				
				<div class="mb-4">
					<h3>Limitation of use</h3>
					<p>Unless otherwise specifically stated in the Terms, your purchase of Playable NFT and underlying NFT does not include ;</p>
					<ol type="i">
						<li>any rights or licenses to META LEGEND Content (as defined in section 6 below)</li>
						<li>a right to transfer the license to other parties or entities unless it forms part of a trade, sale, or give away through the Polygon/Ethereum Network subject to the terms in the Smart Contract,</li>
						<li>a right to use, copy, display or otherwise utilize the Playable NFT for non-personal commercial purposes,</li>
						<li>a right to modify the Playable NFT,</li>
						<li>a right to use the Playable NFT in a way that infringes the rights of others, including but not limited to fundamental human, animal rights and IP rights,</li>
						<li>a right to attempt to acquire additional IP rights to the Playable NFT.</li>
					</ol>
				</div>

				<div class="mb-4">
					<h3>Intellectual property rights</h3>
					<p>The ownership covers both content created prior to or during the use of the App by both parties under these Terms and shall cover the following non-exhaustive list of IP rights: any inventions, patents, copyrights, design rights, trade right, or knowhow, whether registered or not in relation to all source code, database, functionality, software, website design, audio, video, text, photographs, and graphics and any other content (collectively, “META LEGEND Content”) on the Site and in the App.</p>
				</div>

				<div class="mb-4">
					<h3>Payment Terms</h3>
					<p>The purchase of Playable NFT is enabled through the Polygon/Ethereum Network. The financial transactions that you engage with in this network are governed solely by the Polygon/Ethereum Network and we have no insights to or control over such transactions. Any claims or damages that may arise as a result of transactions on the Polygon/Ethereum Network is not part of our responsibility or control, and as such we cannot be held liable to you or any third parties for any claims or damages connected to transactions.</p>
					<p>Each time you utilize a Smart Contract to conduct a transaction, no matter the type of transaction, with another user via the App, you authorize us to collect a commission of the total value of the transaction.</p>
				</div>

				<div class="mb-4">
					<h3>Disclaimer of Warranties</h3>
					<p>Survivalverse Inc to the fullest extent permissible under applicable law, makes no warranties of any kind related to the Site, the App and the Smart Contracts, whether express or implied. This also applies for any communication between us and you after your purchase of Playable NFT.</p>
					<p>The Site, the App and the Smart Contracts are provided “AS IS” and “AS AVAILABLE” and you understand and agree that the Savanna Survival Game is still under production and the Site, the App, these Terms and the purchased Playable NFT may be subject to further development and changes.</p>
					<p>Survivalverse Inc will not be held accountable, responsible or liable for any losses or damages, whether direct or indirect, you incur as a results of your use of Smart Contracts and the Polygon/Ethereum Network, and any payments and transactional actions performed by you in relation thereto.</p>
					<p>Playable NFTs are intangible digital assets that exist only by virtue of the ownership record maintained in the Polygon/Ethereum Network. You agree and understand that Survivalverse Inc has no control over or and makes no warranties with respects to Smart Contracts, or losses due to blockchain or any other features of the Polygon/Ethereum Network.</p>
				</div>

				<div class="mb-4">
					<h3>Limitation of liability</h3>
					<p>Survivalverse Inc cannot be held liable for any indirect, special or incidental damages, such as but not limited to loss of data, loss of goodwill or business reputation, loss of profits (whether incurred directly or indirectly), or any other intangible loss you may incur and you agree even if it has been advised of the possibility of such damages. You agree and understand that the total, aggregated liability to you for any claim arising out of or relating to these Terms, or your use of or access to the Site, the App and the Smart Contracts will be limited to 10% of the total amount you paid to us in a period of 3 months prior to the date the claim arose.</p>
				</div>

				<div class="mb-4">
					<h3>Indemnification</h3>
					<p>You agree to hold harmless and indemnify Survivalverse Inc and its subsidiaries, affiliates, officers, agencies, advertisers, licensors, suppliers or partners against any claim, loss, liability, damage, of any kind or nature, suit, judgment, litigation cost, and attorneys’ fees arising from or in any way related to your breach of these Terms, misuse of the Site and the App, violation of Applicable laws, rules or regulations in connection with your access to or use of the Site and the App.</p>
				</div>

				<div class="mb-4">
					<h3>Privacy</h3>
					<p>When purchasing Playable NFT and using the Site and App, we process your personal information. We are commited to protect your personal information from any unauthorized use, access, and disclosure. For more information on how we collect, store, use, and disclose your personal information, please see our Privacy Policy.</p>
				</div>

				<div class="mb-4">
					<h3>Governing law and dispute resolution</h3>
					<p>These Terms and any dispute relation hereto, or to the use of the Site, the App, and the Smart Contracts, will be governed by and construed in accordance with British Virgin Islands. In the event of any lawsuit or proceeding, the Courts of British Virgin Islands will have exclusive jurisdiction.</p>
					<p>If a dispute arises relating to these Terms, both parties agree to first attempt to resolve the dispute by informal negotiations. If informal negotiations do not result in a satisfying resolution, you agree and understand that the dispute will be finally and exclusively resolved by binding arbitration in accordance with British Virgin Islands.</p>
					<p>No Party may commence any arbitration proceedings in relation to any dispute arising out of this Agreement until it has attempted to settle the dispute by mediation and the mediation has terminated. As a minimum, a Party shall be obliged to aRend the first meeting convened by the mediator.</p>
				</div>

				<div class="mb-4">
					<h3>Termination</h3>
					<p>These Terms shall remain effective and in full force while you use the Site, the App and the Smart Contracts. Termination may only be effectuated by you if you cancel your account and cease to use the Site, and the App.</p>
					<p>Termination or suspension, depending on the cause, may be effectuated by us for any breach of these Terms, misuse of the Site, the App or the Smart Contracts, without warning, in our sole discretion. Termination or suspension may also be effectuated by us for any other action performed by you justifying such termina9on or suspension, including but not limited to, for example, all at our sole discretion :</p>
					<ul>
						<li>Your application of the Site, the App or the Smart Contract for criminal purposes or fraudulent conduct</li>
						<li>Your application of the Site, the App or the Smart Contract with the intention to establish other financial instruments or engage in other financial transactions than already enabled on the Polygon/Ethereum Network and governed by the Smart Contract</li>
						<li>Requirement by applicable law, rule or regulation</li>
						<li>Termination or change of terms by third party service provider in relation to services necessary for us to fulfill our obligations</li>
						<li>Risk of continued services creating a substantial economic burden on us</li>
						<li>Risk of continued services creating a security risk or material technical burden.</li>
						<li>Upon termination your account will be deleted, and your access to the Site and The App will expire and any rights granted in these Terms will expire.</li>
					</ul>
				</div>

				<div class="mb-4">
					<h3>Miscellaneous</h3>
					<p>You understand and agree that these Terms, the Site and App may be subject to changes. You understand that the META LEGEND Game has not yet been released and more obligations and rights may be imposed on or granted to you in the future. It remains your own responsibility to keep yourself updated with changes to these Terms.</p>
				</div>
			</div>
		</div>
		<div class="row" style="margin-top: 60px">
			<div class="col-lg-7 mx-auto">
				<div class="row">
					<div class="col-lg-3 text-center">
						<p>©2024 Meta Legend</p>
					</div>
					<div class="col-lg-3 text-center">
						<a href="faq/privacy-policy" style="color: #ffffff">Privacy Policy</a>
					</div>
					<div class="col-lg-3 text-center">
						<a href="faq/term-of-use" style="color: #ffffff" >Term of Use</a>
					</div>
					<div class="col-lg-3 text-center">
						<a href="mailto:support@metalegend.ai" style="color: #ffffff">support@metalegend.ai</a>
					</div>
				</div>
			</div>
		</div>
	</div>

</section>

