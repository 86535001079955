import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable()
export class UsersService {
  constructor(private http: HttpClient) {}
  token = localStorage.getItem('access_token')
    ? localStorage.getItem('access_token')
    : environment.access_token;

  httpOption = {
    headers: new HttpHeaders({
      Authorization: `Bearer ${this.token}`,
    }),
  };

  getToken(): Observable<any> {
    return this.http.post(
      environment.apiURL + '/auth/login',
      environment.apiConfig
    );
  }

  connectAccount(): Observable<any> {
    var body = {
      _id: localStorage.getItem('account_address'),
    };

    return this.http.post(
      environment.apiURL + '/user/connect',
      body,
      this.httpOption
    );
  }

  updateUser(data, address): Observable<any> {
    let queryParams = new HttpParams();
    queryParams = queryParams.append('_id', address);
    let httpOption = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${this.token}`,
      }),
      params: queryParams,
    };
    return this.http.patch(
      environment.apiURL + '/user/update',
      data,
      httpOption
    );
  }

  getUserDetailByWalletAdd(add: any): Observable<any> {
    let queryParams = new HttpParams();
    queryParams = queryParams.append('_id', add);
    let httpOption = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${this.token}`,
      }),
      params: queryParams,
    };
    return this.http.get(environment.apiURL + '/user/byid', httpOption);
  }

  getUserDetailByPlayerId(id: any): Observable<any> {
    let queryParams = new HttpParams();
    queryParams = queryParams.append('player_id', id);
    let httpOption = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${this.token}`,
      }),
      params: queryParams,
    };
    return this.http.get(environment.apiURL + '/user/byPlayerId', httpOption);
  }

  uploadImage(data): Observable<any> {
    return this.http.patch(
      environment.apiURL + '/user/avatar/update',
      data,
      this.httpOption
    );
  }

  bindUser(data): Observable<any> {
    return this.http.post(
      environment.apiURL + '/game-user/bind',
      data,
      this.httpOption
    );
  }

  checkUser(data): Observable<any> {
    let queryParams = new HttpParams();
    queryParams = queryParams.append('wallet_address', data);
    let httpOption = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${this.token}`,
      }),
      params: queryParams,
    };
    return this.http.get(
      environment.apiURL + '/game-user/isVerified',
      httpOption
    );
  }
}
