import { Component, OnInit } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
  constructor(private modalService: NgbModal, private router: Router) {}

  ngOnInit(): void {}

  async metamaskLogin() {
    const w:any = window;
    let client =
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      );
    
    if(w.ethereum){
      w.ethereum.enable().then((res:any)=>{
        localStorage.setItem("account_address",res[0]);
        this.router.navigate(['profile']).then(() => {
          window.location.reload();
        });
      })
    }else{
      if (client) {
        window.open("https://metamask.app.link/dapp/market.metalegend.ai");
      } else {
        window.open("https://metamask.io/download/", '_blank');
      }
    }
  }

  open(content) {
    this.modalService.open(content, { size: 'md', centered: true });
  }
}
