<section style="margin-top: 50px">
  <div class="container">
    <div class="row">
      <div class="col-lg-12 mb-5">
        <h2>Offer Received</h2>
        <div class="profile-card">
          <div class="table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th>NFT Title</th>
                  <th>Buyer</th>
                  <th>Marketplace Price</th>
                  <th>Offer Price</th>
                  <th></th>
                </tr>
              </thead>
              <tbody *ngIf="receivedList; else noData">
                <tr *ngFor="let item of receivedList">
                  <td>
                    <p style="display: inline-block">
                      <a
                        href="product/detail/{{ item.tokenId }}?token_address={{
                          item.token_address
                        }}&blockchain={{ item.blockchain }}"
                        class="zoom-owner-link"
                        >{{ item.item_name }}</a
                      >
                    </p>
                  </td>
                  <td>
                    <p>
                      {{ item.owner }}
                    </p>
                  </td>
                  <td>
                    <p>{{ item.market_price }}</p>
                  </td>
                  <td>
                    <p>{{ item.offer_price }}</p>
                  </td>
                  <td>
                    <div
                      class="btn-modal mb-0"
                      (click)="open(accept, item.tokenId, item.token_address)"
                    >
                      Accept
                    </div>
                  </td>
                </tr>
              </tbody>
              <ng-template #noData>
                <tbody>
                  <tr>
                    <td colspan="4" style="text-align: center">No data...</td>
                  </tr>
                </tbody>
              </ng-template>
            </table>
          </div>
        </div>
      </div>
    </div>
    <ng-template #accept let-modal>
      <div class="detail-modal">
        <div class="modal-header">
          <h4 class="modal-title" id="modal-basic-title">Accept offer</h4>
          <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="modal.dismiss('Cross click')"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-sm-12">
              <div class="row mb-3">
                <div class="col-sm-12 text-center">
                  <p>Do you want to accept this offer?</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <div class="row col-lg-12 mx-auto">
            <div class="col-sm-6">
              <div class="btn-cancel" (click)="modal.dismiss('Cross click')">
                Cancel
              </div>
            </div>
            <div class="col-sm-6">
              <div class="btn-cancel" (click)="acceptOffer()">Yes</div>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </div>
</section>
<ngx-spinner type="ball-clip-rotate-multiple">
  <p class="loading">Loading Awesomeness...</p>
</ngx-spinner>
