<section style="margin-top: 100px; min-height: calc(100vh - 249px); color:#ffffff;">
  <div class="container">
    <div class="row">
      <div class="col-sm-12 text-center">
        <h1>Page is under construction.</h1>
        <img src="assets/images/construction.png" width="auto" />
      </div>
  </div>
  </div>
</section>

<!--
<section style="margin-top: 60px">
  <div class="container">
    <div class="row col-sm-10 ms-auto me-auto">
      <div class="col-sm-12 text-center">
        <h3 class="title1">Top NFT</h3>
        <p style="margin-bottom: 30px">
          The Top NFTs on WoWSpace, ranked by volume, floor price and other
          statistics.
        </p>
      </div>
      <div class="col-sm-12">
        <div class="row" style="padding: 20px 0; padding-bottom: 30px">
          <div class="col-6 col-sm-5 col-lg-4 col-xl-3 ms-auto">
            <select class="select-option" aria-label="Default select example">
              <option selected>Sort By</option>
              <option value="1">Recently Listed</option>
              <option value="2">Recently Created</option>
              <option value="3">Recently Sold</option>
            </select>
          </div>
        </div>
      </div>
      <div class="col-sm-12">
        <div class="table-responsive">
          <table class="table align-middle table-border product-table">
            <thead>
              <tr>
                <th>ID</th>
                <th>Collection</th>
                <th>Volume</th>
                <th>24h %</th>
                <th>7d %</th>
                <th>Price</th>
                <th>Owners</th>
                <th>Assets</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <p>1</p>
                </td>
                <td>
                  <p>Student - John Smith</p>
                </td>
                <td>
                  <img
                    src="assets/images/eth.svg"
                    width="15px"
                    style="display: inline-block; margin-right: 15px"
                  />
                  <p style="display: inline-block">0.00297</p>
                </td>
                <td>
                  <p style="color: rgb(235, 87, 87)">-5 %</p>
                </td>
                <td>
                  <p style="color: rgb(52, 199, 123)">+50 %</p>
                </td>
                <td>
                  <img
                    src="assets/images/eth.svg"
                    width="15px"
                    style="display: inline-block; margin-right: 15px"
                  />
                  <p style="display: inline-block">0.00297</p>
                </td>
                <td>
                  <p>4.8K</p>
                </td>

                <td>
                  <p>10.0K</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>2</p>
                </td>
                <td>
                  <p>Charizard</p>
                </td>
                <td>
                  <img
                    src="assets/images/eth.svg"
                    width="15px"
                    style="display: inline-block; margin-right: 15px"
                  />
                  <p style="display: inline-block">0.00297</p>
                </td>
                <td>
                  <p style="color: rgb(235, 87, 87)">-5 %</p>
                </td>
                <td>
                  <p style="color: rgb(52, 199, 123)">+50 %</p>
                </td>
                <td>
                  <img
                    src="assets/images/eth.svg"
                    width="15px"
                    style="display: inline-block; margin-right: 15px"
                  />
                  <p style="display: inline-block">0.00297</p>
                </td>
                <td>
                  <p>4.8K</p>
                </td>

                <td>
                  <p>10.0K</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>3</p>
                </td>
                <td>
                  <p>Cartoon</p>
                </td>
                <td>
                  <img
                    src="assets/images/eth.svg"
                    width="15px"
                    style="display: inline-block; margin-right: 15px"
                  />
                  <p style="display: inline-block">0.00297</p>
                </td>
                <td>
                  <p style="color: rgb(235, 87, 87)">-5 %</p>
                </td>
                <td>
                  <p style="color: rgb(52, 199, 123)">+50 %</p>
                </td>
                <td>
                  <img
                    src="assets/images/eth.svg"
                    width="15px"
                    style="display: inline-block; margin-right: 15px"
                  />
                  <p style="display: inline-block">0.00297</p>
                </td>
                <td>
                  <p>4.8K</p>
                </td>

                <td>
                  <p>10.0K</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>4</p>
                </td>
                <td>
                  <p>HELP University</p>
                </td>
                <td>
                  <img
                    src="assets/images/eth.svg"
                    width="15px"
                    style="display: inline-block; margin-right: 15px"
                  />
                  <p style="display: inline-block">0.00297</p>
                </td>
                <td>
                  <p style="color: rgb(235, 87, 87)">-5 %</p>
                </td>
                <td>
                  <p style="color: rgb(52, 199, 123)">+50 %</p>
                </td>
                <td>
                  <img
                    src="assets/images/eth.svg"
                    width="15px"
                    style="display: inline-block; margin-right: 15px"
                  />
                  <p style="display: inline-block">0.00297</p>
                </td>
                <td>
                  <p>4.8K</p>
                </td>

                <td>
                  <p>10.0K</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>5</p>
                </td>
                <td>
                  <p>Student - John Smith</p>
                </td>
                <td>
                  <img
                    src="assets/images/eth.svg"
                    width="15px"
                    style="display: inline-block; margin-right: 15px"
                  />
                  <p style="display: inline-block">0.00297</p>
                </td>
                <td>
                  <p style="color: rgb(235, 87, 87)">-5 %</p>
                </td>
                <td>
                  <p style="color: rgb(52, 199, 123)">+50 %</p>
                </td>
                <td>
                  <img
                    src="assets/images/eth.svg"
                    width="15px"
                    style="display: inline-block; margin-right: 15px"
                  />
                  <p style="display: inline-block">0.00297</p>
                </td>
                <td>
                  <p>4.8K</p>
                </td>

                <td>
                  <p>10.0K</p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</section>
-->
