<app-navbar></app-navbar>
<div style="margin-top: 130px">
	<div class="container">
		<div class="row">
          <div class="col-12 col-sm-5 col-lg-4 mb-4">
            <div class="btn-search cursor-pointer" (click)="open(search, 'xl')">
              <img
                src="assets/images/search-icon.svg"
                style="margin-right: 15px"
              /># insert keyword here
            </div>

            <!-- Search modal -->
            <ng-template #search let-modal>
              <div class="detail-modal">
                <div class="modal-body">
                  <div class="row">
                    <div class="col-sm-12">
                      <button
                        type="button"
                        class="close"
                        aria-label="Close"
                        (click)="modal.dismiss('Cross click')"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                      <div class="text-center" style="padding: 40px 30px">
                        <h4
                          style="color: #ffffff"
                          class="modal-title ms-0 text-center"
                          id="modal-basic-title"
                        >
                          Name your search
                        </h4>
                        <p style="margin-bottom: 40px">
                          Give your search a name
                        </p>
                        <div class="row col-lg-10 mx-auto">
                          <div class="col-lg-2 mb-2 my-auto">
                            <p></p>
                          </div>
                          <div class="col-lg-7 mb-2">
                            <input
                              class="form-control search-input"
                              type="search"
                              placeholder="Search keyword	s"
                              aria-label="Search"
                            />
                          </div>
                          <div class="col-lg-3 mb-2">
                            <div
                              class="btn-modal mb-0"
                              (click)="modal.dismiss('Cross click')"
                            >
                              save
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>
          </div>

          <div class="col-12 col-sm-5 col-lg-2 mb-4 ms-auto">
            <select class="select-option">
              <option value="1" selected>Single Items</option>
              <option value="2">Two</option>
              <option value="3">Three</option>
            </select>
          </div>

          <div class="col-12 col-sm-5 col-lg-2 mb-4">
            <select class="select-option">
              <option value="1" selected>Newest</option>
              <option value="2">Oldest</option>
              <option value="3">Popular</option>
            </select>
          </div>
		</div>
	</div>
</div>

<section>
	<div class="container">
		<div class="row">
			<div class="col-sm-6">
        <article (click)="detail()">
					<div class="news-box">
						<img src="assets/images/2.jpg">
						<div>
							<h5 class="detail-owner-text ms-0">Category</h5>
							<h3 class="detail-tab-title mb-1">Lorem ipsum dolor sit ame</h3>
							<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrudn... </p>
						</div>
						<div style="color: #ffffff">
							<fa-icon [icon]="faClock" style="margin-right: 10px;"></fa-icon>Jan 1
						</div>
					</div>
				</article>
			</div>	
      <div class="col-sm-6">
        <article (click)="detail()">
          <div class="news-box">
            <img src="assets/images/2.jpg">
            <div>
              <h5 class="detail-owner-text ms-0">Category</h5>
              <h3 class="detail-tab-title mb-1">Lorem ipsum dolor sit ame</h3>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrudn... </p>
            </div>
            <div>
              <fa-icon [icon]="faClock" style="margin-right: 10px; color: #ffffff"></fa-icon>Jan 1
            </div>
          </div>
        </article>
      </div>
      <div class="col-sm-6">
        <article (click)="detail()">
          <div class="news-box">
            <img src="assets/images/2.jpg">
            <div>
              <h5 class="detail-owner-text ms-0">Category</h5>
              <h3 class="detail-tab-title mb-1">Lorem ipsum dolor sit ame</h3>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrudn... </p>
            </div>
            <div>
              <fa-icon [icon]="faClock" style="margin-right: 10px; color: #ffffff"></fa-icon>Jan 1
            </div>
          </div>
        </article>
      </div>
      <div class="col-sm-6">
        <article (click)="detail()">
          <div class="news-box">
            <img src="assets/images/2.jpg">
            <div>
              <h5 class="detail-owner-text ms-0">Category</h5>
              <h3 class="detail-tab-title mb-1">Lorem ipsum dolor sit ame</h3>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrudn... </p>
            </div>
            <div>
              <fa-icon [icon]="faClock" style="margin-right: 10px; color: #ffffff"></fa-icon>Jan 1
            </div>
          </div>
        </article>
      </div>
		</div>
	</div>
</section>