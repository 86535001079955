import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { NftService } from '../../services/nft.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-received',
  templateUrl: './received.component.html',
  styleUrls: ['./received.component.css'],
})
export class ReceivedComponent implements OnInit {
  userAdd: string;
  receivedList;
  tokenId;
  tokenAdd: string;
  constructor(
    private nftService: NftService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private modalService: NgbModal
  ) {}

  async ngOnInit(): Promise<void> {
    // await this.checkMetamask();
     this.userAdd = localStorage.getItem('account_address');
    this.getOfferReceived();
  }

  async getOfferReceived() {
    await this.nftService.getOfferReceived(this.userAdd).subscribe((data) => {
      if (data.success) {
        this.receivedList = data.result;
        console.log(this.receivedList);
      }
    });
  }

  open(content, tokenId, tokenAdd) {
    this.tokenId = tokenId;
    this.tokenAdd = tokenAdd;
    this.modalService.open(content, { size: 'm', centered: true });
  }

  acceptOffer() {
    this.spinner.show();
  }
}
