import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { NftService } from '../../services/nft.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-made',
  templateUrl: './made.component.html',
  styleUrls: ['./made.component.css'],
})
export class MadeComponent implements OnInit {
  userAdd: string;
  madeList;
  tokenId;
  tokenAdd: string;

  constructor(
    private nftService: NftService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private modalService: NgbModal
  ) {}

  async ngOnInit(): Promise<void> {
    // await this.checkMetamask();
    this.userAdd = localStorage.getItem('account_address');
    this.getOfferMade();
  }

  getOfferMade() {
    this.nftService.getOfferMade(this.userAdd).subscribe((data) => {
      if (data.success) {
        this.madeList = data.result;
        console.log(this.madeList);
      }
    });
  }

  open(content, tokenId, tokenAdd) {
    this.tokenId = tokenId;
    this.tokenAdd = tokenAdd;
    this.modalService.open(content, { size: 'm', centered: true });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  cancelOffer() {
    console.log('token add', this.tokenAdd);
    this.spinner.show();
  }
}
