<section style="padding: 30px; min-height: 100vh; margin-top: 90px">
  <div class="container">
    <div class="row">
      <div class="col-lg-4 mx-auto text-center">
        <h2>Contact Us</h2>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6 mx-auto">
        <div class="row">
          <div class="col-lg-6">
            <p>First Name</p>
            <input
              type="text"
              name="firstName"
              placeholder="First Name"
              class="form-control contact-input"
              #firstName
            />
          </div>
          <div class="col-lg-6">
            <p>Last Name</p>
            <input
              type="text"
              name="lastName"
              placeholder="Last Name"
              class="form-control contact-input"
              #lastName
            />
          </div>
          <!-- <div class="col-lg-12">
						<p>Phone Number *</p>
						<input type="text" name="phoneNo" placeholder="Add Country Code (+66)" class="form-control contact-input">
					</div> -->
          <div class="col-lg-12">
            <p>Email Address * <span *ngIf="email_Inv">required</span></p>
            <input
              type="email"
              name="email"
              placeholder="Email Address"
              class="form-control contact-input"
              #email
            />
          </div>
          <div class="col-lg-12">
            <p>Your Message * <span *ngIf="message_Inv">required</span></p>

            <textarea
              name="problem"
              placeholder="Type your message here..."
              class="form-control contact-input"
              rows="5"
              #message
            ></textarea>
          </div>
          <div class="col-lg-12">
            <p>
              Your Wallet Address * <span *ngIf="wallet_Inv">required</span>
            </p>
            <input
              type="text"
              name="wallet"
              placeholder="Text Number"
              class="form-control contact-input"
              #wallet
            />
          </div>
          <div class="col-lg-6 mx-auto">
            <div
              class="contact-btn cursor-pointer"
              (click)="
                submit(
                  firstName.value,
                  lastName.value,
                  email.value,
                  message.value,
                  wallet.value
                )
              "
            >
              Send
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" style="margin-top: 60px">
      <div class="col-lg-7 mx-auto">
        <div class="row">
          <div class="col-lg-3 text-center">
            <p style="font-family: 'Exo 2', sans-serif">©2022 Canverse Asia</p>
          </div>
          <div class="col-lg-3 text-center">
            <a href="#">Privacy Policy</a>
          </div>
          <div class="col-lg-3 text-center">
            <a href="#">Term of Use</a>
          </div>
          <div class="col-lg-3 text-center">
            <a href="#">play@SavannaSurvival.io</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
