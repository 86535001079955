<section style="margin-top: 50px">
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-3">
        <a
          class="sub-btn filter-btn"
          data-bs-toggle="offcanvas"
          href="#offcanvasExample"
          role="button"
          aria-controls="offcanvasExample"
          style="margin-bottom: 30px"
          >Profiles</a
        >
        <div
          class="offcanvas offcanvas-start filter row"
          tabindex="-1"
          id="offcanvasExample"
          aria-labelledby="offcanvasExampleLabel"
          style="
            background: none;
            border: 1px solid #707070;
            border-radius: 25px;
            background-origin: border-box;
            min-height: calc(100vh - 160px);
            padding: 30px 0;
          "
        >
          <div class="col-sm-12 px-0 h-100">
            <div class="offcanvas-body h-100" style="position: relative">
              <div class="row">
                <div class="col-sm-12 mt-auto px-0">
                  <a
                    class="profile-menu-link"
                    href="#tab-account"
                    [ngClass]="{
                      'profile-menu-link-active': tab === 'account'
                    }"
                    (click)="$event.preventDefault(); tab = 'account'"
                  >
                    <img
                      src="assets/images/dropdown-menu-account-icon.svg"
                      class="menu-icon"
                    />Account
                  </a>
                </div>
                <div class="col-sm-12 px-0">
                  <a
                    class="profile-menu-link"
                    href="#tab-inventory"
                    [ngClass]="{
                      'profile-menu-link-active': tab === 'inventory'
                    }"
                    (click)="$event.preventDefault(); tab = 'inventory'"
                  >
                    <img
                      src="assets/images/dropdown-menu-inventory-icon.svg"
                      class="menu-icon"
                    />Inventory
                  </a>
                </div>
                <div class="col-sm-12 px-0">
                  <a
                    class="profile-menu-link"
                    href="#tab-wallet"
                    [ngClass]="{
                      'profile-menu-link-active': tab === 'wallet'
                    }"
                    (click)="$event.preventDefault(); tab = 'wallet'"
                  >
                    <img
                      src="assets/images/dropdown-menu-wallet-icon.svg"
                      class="menu-icon"
                    />Wallet
                  </a>
                </div>
                <div class="col-sm-12 px-0">
                  <a
                    class="profile-menu-link"
                    href="#tab-history"
                    [ngClass]="{
                      'profile-menu-link-active': tab === 'history'
                    }"
                    (click)="$event.preventDefault(); tab = 'history'"
                  >
                    <img
                      src="assets/images/dropdown-menu-history-icon.svg"
                      class="menu-icon"
                    />History
                  </a>
                </div>
              </div>
              <div class="row" style="position: relative; bottom: 0">
                <div class="col-sm-12 mt-auto px-0">
                  <a class="profile-menu-link" (click)="logout()">
                    <img
                      src="assets/images/dropdown-menu-setting-icon.svg"
                      class="menu-icon"
                    />Logout
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-9">
        <!-- Account -->
        <div
          class="row profile-menu-panel"
          id="tab-account"
          [ngClass]="{ 'profile-menu-panel-active': tab === 'account' }"
        >
          <div class="col-lg-12 mb-5">
            <h2 class="mt-4" style="font-size: 18px;">Account Setting</h2>
            <div class="profile-card">
              <div class="row" *ngIf="userProfile">
                <div class="col-lg-12 my-auto">
                  <div class="profile-detail">
                    <h4>PROFILE</h4>
                    <form [formGroup]="form">
                      <div class="row mb-5">
                        <div class="col-sm-6">
                          <div class="profile-input">
                            <input type="text" name="username" id="username" placeholder="username" formControlName="username" autocomplete="chrome-off"/>
                          </div>
                        </div>
                        <div class="col-sm-2 my-auto">
                              <div class="profile-btn cursor-pointer h-100" (click)="onSubmit(form)">Change name</div>
                        </div>
                      </div>
                    </form>
                    <div class="row">
                      <div class="col-sm-6">
                        <h4>EMAIL ADDRESS</h4>
                        <div class="profile-input">
                          <h3>
                            {{ email ? email : "Haven't Bind Email" }} 
                          </h3>
                          <div class="ms-auto" *ngIf="email">
                            <span>Verify</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Inventory -->
        <div
          class="row profile-menu-panel"
          id="tab-inventory"
          [ngClass]="{ 'profile-menu-panel-active': tab === 'inventory' }"
        >
          <!-- <div class="col-sm-6 col-lg-5 mb-4">
            <div class="row">
              <div class="col-6">
                <div class="btn-profile active">Available</div>
              </div>
             
            </div>
          </div> -->

          <!-- <div class="col-sm-3 ms-auto mb-4">
            <div class="btn-profile active">Available</div>
          </div> -->

          <div class="col-lg-12 mb-5">
            <div class="dashboard-card-bg">
              <div class="row">
                <div class="col-lg-12">
                  <div class="dashboard-tabs">
                    <div class="dashboard-tabs__list">
                      <a
                        href="#tab-savanaLife"
                        class="dashboard-tabs__item"
                        [ngClass]="{
                          'dashboard-tabs__item--active':
                            tabInventory === 'savanaLife'
                        }"
                        (click)="
                          $event.preventDefault(); tabInventory = 'savanaLife'
                        "
                        >Minted</a
                      >
                      <a
                        href="#tab-readyToMint"
                        class="dashboard-tabs__item"
                        [ngClass]="{
                          'dashboard-tabs__item--active':
                            tabInventory === 'readyToMint'
                        }"
                        (click)="
                          $event.preventDefault(); tabInventory = 'readyToMint'
                        "
                        >Ready to Mint</a
                      >
                      <a
                        href="#tab-listed"
                        class="dashboard-tabs__item"
                        [ngClass]="{
                          'dashboard-tabs__item--active':
                            tabInventory === 'listed'
                        }"
                        (click)="
                          $event.preventDefault(); tabInventory = 'listed'
                        "
                        >Listed</a
                      >
                      <a
                        href="#tab-activity"
                        class="dashboard-tabs__item"
                        [ngClass]="{
                          'dashboard-tabs__item--active':
                            tabInventory === 'activity'
                        }"
                        (click)="
                          $event.preventDefault(); tabInventory = 'activity'
                        "
                        >Activity</a
                      >
                    </div>
                    <div class="dashboard-tabs__content">
                      <div
                        class="dashboard-tabs__pane"
                        id="tab-savanaLife"
                        [ngClass]="{
                          'dashboard-tabs__pane--active':
                            tabInventory === 'savanaLife'
                        }"
                      >
                        <div class="row" *ngIf="mintList">
                          <div class="col-sm-12 col-lg-12">
                            <div class="inventory-header">
                              <h1>{{ mintList?.length }}</h1>
                              <div style="display: inline-block; color:#ffffff">
                                <span>Minted</span>
                              </div>
                            </div>
                          </div>
                        </div>

                        <!-- <ng-container *ngIf="!mintList">
                          <div>Loading ...</div>
                        </ng-container> -->
                        <ng-container *ngIf="!mintList">
                          <div class="row">
                            <div
                              class="col-12 margin-auto text-center"
                              style="margin-bottom: 20px"
                            >
                              <img
                                src="assets/images/not-found-icon.svg"
                                width="100px"
                              />
                              <p class="inventory-not-found">
                                Sorry, no results found.
                              </p>
                            </div>
                          </div>
                        </ng-container>
                        <ng-container>
                          <div class="row">
                            <div
                              class="col-8 col-sm-4 col-lg-6 col-xl-3 px-1 margin-auto"
                              *ngFor="let nft of mintList"
                              style="margin-bottom: 20px"
                            >
                              <a
                                href="product/detail/{{
                                  nft?.tokenId
                                }}?token_address={{
                                  nft.token_address
                                }}&blockchain={{ nft.blockchain }}"
                                class="product-detail-link"
                              >
                                <div class="inventory-bg">
                                  <div class="product-wrapper">
                                    <a>
                                      <div class="row">
                                        <div class="col-12">
                                          <div class="row">
                                            <div
                                              class="col-12 text-end"
                                              *ngIf="
                                                nft.token_address ===
                                                '0x4edd7580fa191c7f05a92c6f9f06b25dffcd0d6f'
                                              "
                                            >
                                              <p
                                                class="recent-lv text-right"
                                                style="color: #ffffff"
                                              >
                                                Tier
                                                <span>{{
                                                  nft.animal_attributes[0].value
                                                }}</span>
                                              </p>
                                            </div>
                                            <div
                                              class="col-12 text-end"
                                              *ngIf="
                                                nft.token_address ===
                                                '0xcea34fa634f6991dcf746047855d06b7326b979b'
                                              "
                                            >
                                              <p
                                                class="recent-lv text-right"
                                                style="color: #fcbf10"
                                              >
                                                LV:
                                                <span>{{
                                                  nft.attributes[0].value
                                                }}</span>
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="row">
                                        <div class="col-sm-12">
                                          <div
                                            class="nft-image"
                                            style="margin-bottom: 20px"
                                            *ngIf="nft.file_type == 'mp4'"
                                          >
                                            <video width="100%">
                                              <source
                                                [src]="nft?.media_uri"
                                                type="video/mp4"
                                                preload="none"
                                              />
                                            </video>
                                          </div>
                                          <div
                                            class="nft-image"
                                            style="max-height: 265px"
                                            *ngIf="nft.file_type !== 'mp4'"
                                          >
                                            <div
                                              class="nft-image"
                                              style="height: 80%"
                                            >
                                              <img [src]="nft?.media_uri" />
                                            </div>
                                          </div>
                                        </div>
                                        <div class="col-sm-12">
                                          <div class="row">
                                            <div class="col-12">
                                              <div class="btn-inventory-detail">
                                                <div class="text-center">
                                                  <p>{{ nft?.animal_name }}</p>
                                                  <p>{{ nft?.name }}</p>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </a>
                                  </div>
                                </div>
                              </a>
                            </div>
                          </div>
                        </ng-container>
                      </div>
                      <div
                        class="dashboard-tabs__pane"
                        id="tab-readyToMint"
                        [ngClass]="{
                          'dashboard-tabs__pane--active':
                            tabInventory === 'readyToMint'
                        }"
                      >
                        <div class="row" *ngIf="offChainList">
                          <div class="col-sm-10 col-lg-10">
                            <div class="inventory-header">
                              <h1>{{ offChainList?.length }}</h1>
                              <div style="display: inline-block; color:#ffffff;">
                                <span>Ready to<br />Mint</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <ng-container *ngIf="!offChainList">
                          <div class="row">
                            <div
                              class="col-12 margin-auto text-center"
                              style="margin-bottom: 20px"
                            >
                              <img
                                src="assets/images/not-found-icon.svg"
                                width="100px"
                              />
                              <p class="inventory-not-found">
                                Sorry, no results found.
                              </p>
                            </div>
                          </div>
                        </ng-container>
                        <ng-container>
                          <div class="row">
                            <div
                              class="col-8 col-sm-4 col-lg-6 col-xl-3 margin-auto"
                              *ngFor="let nft of offChainList"
                              style="margin-bottom: 20px"
                            >
                              <a
                                href="product/detail/{{
                                  nft?.tokenId
                                }}?token_address={{
                                  nft.token_address
                                }}&blockchain={{ nft.blockchain }}&off_chain=1"
                                class="product-detail-link"
                              >
                                <div class="inventory-bg">
                                  <div class="product-wrapper">
                                    <a>
                                      <div class="row">
                                        <div class="col-12">
                                          <div class="row">
                                            <div
                                              class="col-12 text-end"
                                              *ngIf="
                                                nft.token_address ===
                                                '0x4edd7580fa191c7f05a92c6f9f06b25dffcd0d6f'
                                              "
                                            >
                                              <p
                                                class="recent-lv text-right"
                                                style="color: #ffffff"
                                              >
                                                Tier
                                                <span>{{
                                                  nft.animal_attributes[0].value
                                                }}</span>
                                              </p>
                                            </div>
                                            <div
                                              class="col-12 text-end"
                                              *ngIf="
                                                nft.token_address ===
                                                '0xcea34fa634f6991dcf746047855d06b7326b979b'
                                              "
                                            >
                                              <p
                                                class="recent-lv text-right"
                                                style="color: #fcbf10"
                                              >
                                                LV:
                                                <span>{{
                                                  nft.attributes[0].value
                                                }}</span>
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="row">
                                        <div class="col-sm-12">
                                          <div
                                            class="nft-image"
                                            style="margin-bottom: 20px"
                                            *ngIf="nft.file_type == 'mp4'"
                                          >
                                            <video width="100%">
                                              <source
                                                [src]="nft?.media_uri"
                                                type="video/mp4"
                                                preload="none"
                                              />
                                            </video>
                                          </div>
                                          <div
                                            class="nft-image"
                                            style="max-height: 265px"
                                            *ngIf="nft.file_type !== 'mp4'"
                                          >
                                            <div
                                              class="nft-image"
                                              style="height: 80%"
                                            >
                                              <img [src]="nft?.media_uri" />
                                            </div>
                                          </div>
                                        </div>
                                        <div class="col-sm-12">
                                          <div class="row">
                                            <div class="col-12">
                                              <div class="btn-inventory-detail">
                                                <div class="text-center">
                                                  <p>{{ nft?.animal_name }}</p>
                                                  <p>{{ nft?.name }}</p>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </a>
                                  </div>
                                </div>
                              </a>
                            </div>
                          </div>
                        </ng-container>
                      </div>
                      <div
                        class="dashboard-tabs__pane"
                        id="tab-listed"
                        [ngClass]="{
                          'dashboard-tabs__pane--active':
                            tabInventory === 'listed'
                        }"
                      >
                        <div class="row" *ngIf="listedList">
                          <div class="col-sm-10 col-lg-10">
                            <div class="inventory-header">
                              <h1>{{ listedList?.length }}</h1>
                              <div style="display: inline-block; color:#ffffff;">
                                <span>Listed</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <ng-container *ngIf="!listedList">
                          <div class="row">
                            <div
                              class="col-12 margin-auto text-center"
                              style="margin-bottom: 20px"
                            >
                              <img
                                src="assets/images/not-found-icon.svg"
                                width="100px"
                              />
                              <p class="inventory-not-found">
                                Sorry, no results found.
                              </p>
                            </div>
                          </div>
                        </ng-container>
                        <ng-container>
                          <div class="row">
                            <div
                              class="col-8 col-sm-4 col-lg-6 col-xl-3 margin-auto"
                              *ngFor="let nft of listedList"
                              style="margin-bottom: 20px"
                            >
                              <a
                                href="product/detail/{{
                                  nft?.tokenId
                                }}?token_address={{
                                  nft.token_address
                                }}&blockchain={{ nft.blockchain }}"
                                class="product-detail-link"
                              >
                                <div class="inventory-bg">
                                  <div class="product-wrapper">
                                    <a>
                                      <div class="row">
                                        <div class="col-12">
                                          <div class="row">
                                            <div
                                              class="col-12 text-end"
                                              *ngIf="
                                                nft.token_address ===
                                                '0x4edd7580fa191c7f05a92c6f9f06b25dffcd0d6f'
                                              "
                                            >
                                              <p
                                                class="recent-lv text-right"
                                                style="color: #ffffff"
                                              >
                                                Tier
                                                <span>{{
                                                  nft.animal_attributes[0].value
                                                }}</span>
                                              </p>
                                            </div>
                                            <div
                                              class="col-12 text-end"
                                              *ngIf="
                                                nft.token_address ===
                                                '0xcea34fa634f6991dcf746047855d06b7326b979b'
                                              "
                                            >
                                              <p
                                                class="recent-lv text-right"
                                                style="color: #fcbf10"
                                              >
                                                LV:
                                                <span>{{
                                                  nft.attributes[0].value
                                                }}</span>
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="row">
                                        <div class="col-sm-12">
                                          <div
                                            class="nft-image"
                                            style="margin-bottom: 20px"
                                            *ngIf="nft.file_type == 'mp4'"
                                          >
                                            <video width="100%">
                                              <source
                                                [src]="nft?.media_uri"
                                                type="video/mp4"
                                                preload="none"
                                              />
                                            </video>
                                          </div>
                                          <div
                                            class="nft-image"
                                            style="max-height: 265px"
                                            *ngIf="nft.file_type !== 'mp4'"
                                          >
                                            <div
                                              class="nft-image"
                                              style="height: 80%"
                                            >
                                              <img [src]="nft?.media_uri" />
                                            </div>
                                          </div>
                                        </div>
                                        <div class="col-sm-12">
                                          <div class="row">
                                            <div class="col-12">
                                              <div class="btn-inventory-detail">
                                                <div class="text-center">
                                                  <p>{{ nft?.animal_name }}</p>
                                                  <p>{{ nft?.name }}</p>
                                                  <p>
                                                    {{ nft?.price }} polygon
                                                  </p>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </a>
                                  </div>
                                </div>
                              </a>
                            </div>
                          </div>
                        </ng-container>
                      </div>
                      <div
                        class="dashboard-tabs__pane"
                        id="tab-activity"
                        [ngClass]="{
                          'dashboard-tabs__pane--active':
                            tabInventory === 'activity'
                        }"
                      >
                        <div class="row">
                          <div class="col-sm-12 col-lg-12">
                            <div class="inventory-header">
                              <h1>1</h1>
                              <div style="display: inline-block; color:#ffffff">
                                <span>Ticket</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <ng-container *ngIf="!nftList">
                          <div style="color:#ffffff">Loading ...</div>
                        </ng-container>
                        <ng-container *ngIf="nftList && nftList.length == 0">
                          <div class="row">
                            <div
                              class="col-12 margin-auto text-center"
                              style="margin-bottom: 20px"
                            >
                              <div class="login-btn">Purchase Mystery Box</div>
                            </div>
                          </div>
                        </ng-container>
                        <ng-container>
                          <div class="profile-card">
                            <div class="row">
                              <div class="col-sm-8 col-lg-6 my-auto">
                                <div class="redeem-bg">
                                  <img
                                    src="assets/images/activity-card-dummy.svg"
                                    width="150px"
                                  />
                                </div>
                              </div>
                              <div class="col-sm-3 col-lg-2 ms-auto my-auto">
                                <div class="profile-btn cursor-pointer">
                                  Redeem
                                </div>
                              </div>
                            </div>
                          </div>
                        </ng-container>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Wallet -->
        <div
          class="row profile-menu-panel"
          id="tab-wallet"
          [ngClass]="{ 'profile-menu-panel-active': tab === 'wallet' }"
        >
          <div class="col-lg-12 mb-5" *ngIf="userProfile">
            <h2>Wallet</h2>
            <div class="wallet-card" style="min-height: calc(90vh - 160px)">
              <div class="row h-100">
                <!-- <div class="col-sm-3 col-lg-2 my-auto">
                 <img
                    src="assets/images/wallet.jpg"
                    style="
                      margin: 20px auto;
                      display: block;
                      width: 84.721px;
                      height: 82.499px;
                    "
                  />
                </div>-->
                <div class="col-sm-9 col-lg-10 my-auto">
                  <div class="wallet-detail">
                    <h3>
                      {{ userProfile._id | slice : 0 : 15 }}
                      <span>polygon</span>
                    </h3>
                    <p style="font-family: 'Poppins', sans-serif; color: #9e29c1">
                      {{ userProfile._id }}...<img
                        src="assets/images/copy-icon.svg" 
                        style="margin-left: 15px; cursor: pointer;"
						
                        (click)="callServiceToCopy()"
                      />
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- History -->
        <div
          class="row profile-menu-panel"
          id="tab-history"
          [ngClass]="{ 'profile-menu-panel-active': tab === 'history' }"
        >
          <div class="col-lg-12 mb-5">
            <div class="dashboard-card-bg">
              <div class="row">
                <div class="col-lg-12">
                  <div class="dashboard-tabs">
                    <div class="dashboard-tabs__list">
                      <a
                        href="#tab-transactionHistory"
                        class="dashboard-tabs__item"
                        [ngClass]="{
                          'dashboard-tabs__item--active':
                            tabHistory === 'transactionHistory'
                        }"
                        (click)="
                          $event.preventDefault();
                          tabHistory = 'transactionHistory'
                        "
                        >Transaction History</a
                      >
                      <!-- <a
                        href="#tab-balanceHistory"
                        class="dashboard-tabs__item"
                        [ngClass]="{
                          'dashboard-tabs__item--active':
                            tabHistory === 'balanceHistory'
                        }"
                        (click)="
                          $event.preventDefault(); tabHistory = 'balanceHistory'
                        "
                        >Balance History</a
                      > -->
                    </div>
                    <div class="dashboard-tabs__content">
                      <div
                        class="dashboard-tabs__pane"
                        id="tab-transactionHistory"
                        [ngClass]="{
                          'dashboard-tabs__pane--active':
                            tabHistory === 'transactionHistory'
                        }"
                      >
                        <div class="row">
                          <div class="col-sm-12">
                            <table  class="table custom-table" style="color:#ffffff;">
                              <thead>
                                <tr>
                                  <th>Item</th>
                                  <th>Seller</th>
                                  <th>Buyer</th>
                                  <th>Amount</th>
                                  <th>Time</th>
                                </tr>
                              </thead>
                              <tbody *ngIf="historyList; else noData">
                                <tr *ngFor="let item of historyList?.slice(historyPage * historyPaginate, (historyPage + 1) * historyPaginate)">
                                  <td>#{{ item.tokenId }}</td>
                                  <td>{{ item.seller }}</td>
                                  <td>{{ item.buyer }}</td>
                                  <td>
                                    {{ item.price }} {{ item.blockchain }}
                                  </td>
                                  <td>{{ item.timestamp * 1000 | date }}</td>
                                </tr>
                              </tbody>
                              <ng-template #noData>
                                <tbody>
                                  <tr>
                                    <td colspan="5">
                                      <div
                                        class="col-12 margin-auto text-center"
                                        style="margin-bottom: 20px"
                                      >
                                        <img
                                          src="assets/images/not-found-icon.svg"
                                          width="100px"
                                        />
                                        <p class="inventory-not-found">
                                          Sorry, no results found.
                                        </p>
                                        <!-- <p class="inventory-try">
                                          Please try another search.
                                        </p> -->
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              </ng-template>
                            </table>
                            <app-pagination [(page)]="historyPage" [maxPage]="maxHistoryPage()"></app-pagination>
                          </div>
                        </div>
                      </div>
                      <!-- <div
                        class="dashboard-tabs__pane"
                        id="tab-balanceHistory"
                        [ngClass]="{
                          'dashboard-tabs__pane--active':
                            tabHistory === 'balanceHistory'
                        }"
                      >
                        <div class="row">
                          <div class="col-sm-12 col-lg-12">
                            <div class="inventory-header">
                              <h1>12</h1>
                              <div style="display: inline-block">
                                <span>Savana<br />Land</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div
                            class="col-8 col-sm-4 col-lg-6 col-xl-3 margin-auto"
                            *ngFor="let nft of nftList"
                            style="margin-bottom: 20px"
                          >
                            <div class="inventory-bg">
                              <div class="product-wrapper">
                                <a>
                                  <div class="row">
                                    <div class="col-12">
                                      <div class="row">
                                        <div
                                          class="col-12 text-end"
                                          *ngIf="
                                            nft.token_address ===
                                            '0x4edd7580fa191c7f05a92c6f9f06b25dffcd0d6f'
                                          "
                                        >
                                          <p
                                            class="recent-lv text-right"
                                            style="color: #fcbf10"
                                          >
                                            Tier
                                            <span>{{
                                              nft.animal_attributes[0].value
                                            }}</span>
                                          </p>
                                          <img
                                            src="assets/images/recent-chart-down.svg"
                                            style="float: right"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="row">
                                    <div class="col-sm-12">
                                      <div class="nft-image">
                                        <img [src]="nft?.item_image" />
                                      </div>
                                    </div>
                                    <div class="col-sm-12">
                                      <div class="row">
                                        <div class="col-12">
                                          <div class="btn-inventory-detail">
                                            <div class="text-center">
                                              <p>{{ nft?.animal_name }}</p>
                                              <h3>{{ nft?.item_name }}</h3>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

