<section style="margin-top: 60px">
  <div class="container">
    <div class="row">
      <div class="col-sm-10 mx-auto mb-4">
        <h2>Notification Setting</h2>
        <p>Allow you turn on or off the notification to your activity.</p>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-10 mx-auto">
        <form>
          <div class="row mb-3">
            <div class="col-sm-1 col-2">
              <div class="form-check">
                <input
                  class="form-check-input"
				  style="background-color:#8620a4; border:  1px solid #ffffff;"
                  type="checkbox"
                  value=""
                  id="sold"
                  checked
                />
                <label class="form-check-label" for="sold"> </label>
              </div>
            </div>
            <div class="col-sm-11 col-10">
              <h5>Item Sold</h5>
              <p>When someone purchased one of your items</p>
            </div>
            <hr />
          </div>
          <div class="row mb-3">
            <div class="col-sm-1 col-2">
              <div class="form-check">
                <input
                  class="form-check-input"
				   style="background-color:#8620a4; border:  1px solid #ffffff;"
                  type="checkbox"
                  value=""
                  id="activity"
                  checked
                />
                <label class="form-check-label" for="activity"> </label>
              </div>
            </div>
            <div class="col-sm-11 col-10">
              <h5>Bid Activity</h5>
              <p>When someone purchased one of your items</p>
            </div>
            <hr />
          </div>
          <div class="row mb-3">
            <div class="col-sm-1 col-2">
              <div class="form-check">
                <input
                  class="form-check-input"
				   style="background-color:#8620a4; border:  1px solid #ffffff;"
                  type="checkbox"
                  value=""
                  id="sold"
                  checked
                />
                <label class="form-check-label" for="sold"> </label>
              </div>
            </div>
            <div class="col-sm-11 col-10">
              <h5>Price Change</h5>
              <p>When an item you made an offer on changes in price</p>
            </div>
            <hr />
          </div>
          <div class="row mb-3">
            <div class="col-sm-1 col-2">
              <div class="form-check">
                <input
                  class="form-check-input"
				   style="background-color:#8620a4; border:  1px solid #ffffff;"
                  type="checkbox"
                  value=""
                  id="sold"
                  checked
                />
                <label class="form-check-label" for="sold"> </label>
              </div>
            </div>
            <div class="col-sm-11 col-10">
              <h5>Item Sold</h5>
              <p>When someone purchased one of your items</p>
            </div>
            <hr />
          </div>
          <div class="row mb-3">
            <div class="col-sm-1 col-2">
              <div class="form-check">
                <input
                  class="form-check-input"
				   style="background-color:#8620a4; border:  1px solid #ffffff;"
                  type="checkbox"
                  value=""
                  id="sold"
                  checked
                />
                <label class="form-check-label" for="sold"> </label>
              </div>
            </div>
            <div class="col-sm-11 col-10">
              <h5>Auction Expiration</h5>
              <p>When a Dutch or English auction you created ends</p>
            </div>
            <hr />
          </div>
          <div class="row mb-3">
            <div class="col-sm-1 col-2">
              <div class="form-check">
                <input
                  class="form-check-input"
				   style="background-color:#8620a4; border:  1px solid #ffffff;"
                  type="checkbox"
                  value=""
                  id="sold"
                  checked
                />
                <label class="form-check-label" for="sold"> </label>
              </div>
            </div>
            <div class="col-sm-11 col-10">
              <h5>Outbid</h5>
              <p>When an offer you placed is exceeded by another user</p>
            </div>
            <hr />
          </div>
          <div class="row mb-3">
            <div class="col-sm-1 col-2">
              <div class="form-check">
                <input
                  class="form-check-input"
				   style="background-color:#8620a4; border:  1px solid #ffffff;"
                  type="checkbox"
                  value=""
                  id="sold"
                  checked
                />
                <label class="form-check-label" for="sold"> </label>
              </div>
            </div>
            <div class="col-sm-11 col-10">
              <h5>Successful Purchase</h5>
              <p>When you successfully buy an item</p>
            </div>
            <hr />
          </div>
          <div class="row mb-3">
            <div class="col-sm-1 col-2">
              <div class="form-check">
                <input
                  class="form-check-input"
				   style="background-color:#8620a4; border:  1px solid #ffffff;"
                  type="checkbox"
                  value=""
                  id="sold"
                  checked
                />
                <label class="form-check-label" for="sold"> </label>
              </div>
            </div>
            <div class="col-sm-11 col-10">
              <h5>OpenSea Newsletter</h5>
              <p>Occasional updates from the OpenSea team</p>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</section>
