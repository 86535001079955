import {
  Component,
  OnInit,
  Input,
  ViewChild,
  TemplateRef,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AngularFirestore } from '@angular/fire/firestore';
import {
  faSearch,
  faEye,
  faInfoCircle,
  faBookmark,
  faUser,
  faTag,
  faChartLine,
  faSort,
} from '@fortawesome/free-solid-svg-icons';
import { faHeart } from '@fortawesome/free-regular-svg-icons';
import { faBuffer } from '@fortawesome/free-brands-svg-icons';
import { Chart } from 'angular-highcharts';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { NftService } from '../../services/nft.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

// import {
//   PlainGalleryConfig,
//   Image,
//   PlainGalleryStrategy,
//   LineLayout
// } from '@ks89/angular-modal-gallery';

@Component({
  selector: 'app-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.css'],
})
export class DetailComponent implements OnInit {
  @Input() tab: 'lifeInfo' | 'stats' | 'land' | 'fusion' = 'lifeInfo';
  @ViewChild('approval', { static: false }) approvalModal: TemplateRef<void>;
  constructor(
    private modalService: NgbModal,
    private arouter: ActivatedRoute,
    private router: Router,
    private afs: AngularFirestore,
    private nftService: NftService,
    private spinner: NgxSpinnerService,
    private activatedRoute: ActivatedRoute,
  ) {}

  specification;
  showToggle = {};

  faSearch = faSearch;
  faHeart = faHeart;
  faEye = faEye;
  faInfoCircle = faInfoCircle;
  faBookmark = faBookmark;
  faUser = faUser;
  faTag = faTag;
  faBuffer = faBuffer;
  faChartLine = faChartLine;
  faSort = faSort;
  nft;
  key;
  closeResult = '';
  time = { hour: 13, minute: 30 };
  spinners = false;

  selectExpire = [];
  selectedExpire = [];
  settingExpire = {};

  selectBlockchain = [];
  selectedBlockchain = [];
  settingBlockchain = {};

  nftList;
  tradingList;
  historyList;
  collection;
  storeAddress;
  sellAmountInvalid = false;
  sellAmountErrMsg;
  offerAmountInvalid = false;
  offerAmountErrMsg;
  makeOfferAmountInvalid = false;
  makeOfferAmountErrMsg;
  currentOffer = 0;
  currentOfferAddress;
  OfferStatus = false;
  sellPrice = 0.01;
  tokenAdd;
  blockchain;
  offChain;
  claimText = 'Retrieve coupon';
  claimStatus = false;
  signature;

  chart = new Chart({
    chart: {
      type: 'line',
    },
    title: {
      text: '',
    },
    credits: {
      enabled: false,
    },
    series: [
      {
        name: 'Line 1',
        type: 'line',
        data: [1, 1.5, 1, 2],
        color: '#E6211A',
      },
    ],
  });

  ngOnInit(): void {
    this.storeAddress = localStorage.getItem('account_address');
    this.key = this.arouter.snapshot.params['key'];
    this.activatedRoute.queryParams.subscribe((params) => {
      this.tokenAdd = params['token_address'];
      this.blockchain = params['blockchain'];
      this.offChain = params['off_chain'];
    });

    if (this.tokenAdd === '0xcea34fa634f6991dcf746047855d06b7326b979b') {
      this.getGameNftById();
    } else {
      this.getAnimalNftById();
    }

    // this.addViewer();
    // this.contractEvent.startListener();
  }

  getAnimalNftById() {
    this.nftService.getAnimalNftByTokenId(this.key).subscribe((data) => {
      this.nft = data.result;
      console.log('nft', this.nft);

      if (this.nft.contract_address) {
        this.sellPrice = this.nft.price;
        this.getCurrentOfferByTokenId(
          this.key,
          this.nft.contract_address,
          this.nft.blockchain
        );
      } else {
        this.getGameNftById();
      }
    });
  }

  getGameNftById() {
    console.log(this.key, this.tokenAdd, this.blockchain);
    this.nftService
      .getGameNftByTokenId(this.key, this.tokenAdd, this.blockchain)
      .subscribe((data) => {
        this.nft = data.result;
        console.log('nft', this.nft);
        this.sellPrice = this.nft.price;

        this.getCurrentOfferByTokenId(
          this.key,
          this.nft.token_address,
          this.nft.blockchain
        );
      });
  }

  getCurrentOfferByTokenId(tokenId, contract_address, blockchain) {
    this.nftService
      .getCurrentOffer(tokenId, contract_address, blockchain)
      .subscribe((data) => {
        console.log('history', data);
        if (data.success) {
          this.currentOffer = data.result.amount;
          this.currentOfferAddress = data.result.current_offer;
          this.OfferStatus = true;
        }
      });
  }

  toggleTag(key) {
    this.showToggle[key] = !this.showToggle[key];
  }

  showQuickview(content) {
    this.modalService.open(content, { size: 'xl', centered: true });
  }

  productDetail() {
    this.router.navigate(['product/detail']);
  }

  open(content, type) {
    if (
      content._declarationTContainer.localNames[0] === 'toSell' ||
      content._declarationTContainer.localNames[0] === 'toOffer'
    ) {
      this.modalService.dismissAll('toMarket');
    }
    this.modalService.open(content, { size: type, centered: true });
  }

  async setApproval() {
    var tokenAddress;
    if (this.nft.contract_address) {
      tokenAddress = this.nft.contract_address;
    }
    if (this.nft.token_address) {
      tokenAddress = this.nft.token_address;
    }
  }

  async sellToMarket(sellPrice) {
    if (sellPrice < 0.01) {
      this.sellAmountInvalid = true;
      this.sellAmountErrMsg = 'Amount is required and minimum amount is 0.01';
    } else {
      this.sellAmountInvalid = false;
    }

    if (this.sellAmountInvalid === false) {
      try {
        this.spinner.show();
        var tokenAddress;
        if (this.nft.contract_address) {
          tokenAddress = this.nft.contract_address;
        }
        if (this.nft.token_address) {
          tokenAddress = this.nft.token_address;
        }
      
          if (this.tokenAdd === '0xcea34fa634f6991dcf746047855d06b7326b979b') {
            this.getGameNftById();
          } else {
            this.getAnimalNftById();
          }
      } catch (error) {
        console.log('err', error);
        this.spinner.hide();
        if (error.data.message.includes('Require owner approval')) {
          this.modalService.dismissAll('sell');
          this.modalService.open(this.approvalModal);
        } else {
          Swal.fire({
            title: 'Ooops...',
            text: 'Something is wrong. Please try again later.',
            icon: 'error',
          });
        }
      }
    }
  }

  async buyNft() {
    console.log('99', this.storeAddress);
    if (this.storeAddress !== 'undefined' && this.storeAddress !== null) {
      //start loading
      this.spinner.show();
      try {
        var tokenAddress;
        if (this.nft.contract_address) {
          tokenAddress = this.nft.contract_address;
        }
        if (this.nft.token_address) {
          tokenAddress = this.nft.token_address;
        }
       
          if (this.tokenAdd === '0xcea34fa634f6991dcf746047855d06b7326b979b') {
            this.getGameNftById();
          } else {
            this.getAnimalNftById();
          }
         
      } catch (err) {
        console.log(err.message);
        this.spinner.hide();
        this.modalService.dismissAll('buy');
        Swal.fire({
          title: 'Ooops...',
          text: 'Something is wrong. Please try again later.',
          icon: 'error',
        });
      }
    }
    // else {
    //   this.router.navigate(['login']);
    //   this.modalService.dismissAll();
    // }
  }

  changeListStatus(tokenAdd, status) {
    this.spinner.show();
    if (tokenAdd === '0x4edd7580fa191c7f05a92c6f9f06b25dffcd0d6f') {
      //animal
      var param = {
        isListed: !status,
      };

      this.nftService
        .updateAnimalSale(param, this.nft.tokenId, this.nft.blockchain)
        .subscribe((data) => {
          if (data.result) {
            if (status) {
              Swal.fire({
                title: 'Success!',
                text: 'This NFT is delisted.',
                icon: 'success',
              });
            } else {
              Swal.fire({
                title: 'Success!',
                text: 'This NFT is listed.',
                icon: 'success',
              });
            }
            this.getAnimalNftById();
            this.spinner.hide();
            this.modalService.dismissAll('delisted');
          }
        });
    } else if (tokenAdd === '0xcea34fa634f6991dcf746047855d06b7326b979b') {
      //game
      var param = {
        isListed: !status,
      };
      this.nftService
        .updateGameSale(param, this.nft.tokenId, tokenAdd, this.nft.blockchain)
        .subscribe((data) => {
          if (data.result) {
            if (status) {
              Swal.fire({
                title: 'Success!',
                text: 'This NFT is delisted.',
                icon: 'success',
              });
            } else {
              Swal.fire({
                title: 'Success!',
                text: 'This NFT is listed.',
                icon: 'success',
              });
            }
            this.getGameNftById();
            this.spinner.hide();
            this.modalService.dismissAll('delisted');
          }
        });
    }
  }

  async onMakeOffer(amount) {
    if (amount === undefined) {
      this.makeOfferAmountInvalid = true;
      this.makeOfferAmountErrMsg = 'Amount is required';
      return;
    } else if (!this.OfferStatus && amount <= this.sellPrice) {
      this.makeOfferAmountInvalid = true;
      this.makeOfferAmountErrMsg =
        'The offer amount is need bigger than the selling amount. <br> Current price is ' +
        this.sellPrice +
        '.';
      return;
    } else if (!this.OfferStatus && amount < 0.01) {
      this.makeOfferAmountInvalid = true;
      this.makeOfferAmountErrMsg = 'The minimum offer amount is 0.01 ';
      return;
    } else if (this.OfferStatus === true && amount < this.currentOffer * 1.1) {
      this.makeOfferAmountInvalid = true;
      this.makeOfferAmountErrMsg =
        'The minimum bid offer amount at least increase 10% ';
      return;
    }
    //start loading
    this.spinner.show();
    try {
      var tokenAddress;
      if (this.nft.contract_address) {
        tokenAddress = this.nft.contract_address;
      }
      if (this.nft.token_address) {
        tokenAddress = this.nft.token_address;
      }
    

        if (this.tokenAdd === '0xcea34fa634f6991dcf746047855d06b7326b979b') {
          this.getGameNftById();
        } else {
          this.getAnimalNftById();
        }
       
    } catch (err) {
      console.log(err);
      this.spinner.hide();
      this.modalService.dismissAll('offer');
      Swal.fire({
        title: 'Ooops...',
        text: 'Something is wrong. Please try again later.',
        icon: 'error',
      });
    }
    this.makeOfferAmountErrMsg = '';
    this.makeOfferAmountInvalid = false;
  }

  getTradingHistoryByTokenId(tokenId) {
    var data = {
      tokenId: tokenId,
    };
    try {
      this.nftService.tradingHIstoryByTokenId(data).subscribe((data) => {
        this.tradingList = data.result;
      });
    } catch (err) {
      console.log(err);
    }
  }

  async onAcceptOffer() {
    //start loading
    this.spinner.show();
    try {
        if (this.tokenAdd === '0xcea34fa634f6991dcf746047855d06b7326b979b') {
          this.getGameNftById();
        } else {
          this.getAnimalNftById();
        }
    } catch (err) {
      console.log(err);
      this.spinner.hide();
      this.modalService.dismissAll('acceptOffer');
      Swal.fire({
        title: 'Ooops...',
        text: 'Something is wrong. Please try again later.',
        icon: 'error',
      });
    }
  }

  onClaimCoupon() {
    this.spinner.show();
    var body = {
      address: this.storeAddress,
      tokenId: Number(this.nft.tokenId),
      token_address: this.tokenAdd,
      blockchain: this.blockchain,
    };
    try {
      this.nftService.claimNFT(body).subscribe((data) => {
        console.log(data);

        if (data.success) {
          this.signature = data.result.signature;

          //end loading
          this.spinner.hide();
          this.claimText = 'Coupon Ready';
          this.claimStatus = true;
        } else {
          this.spinner.hide();
          Swal.fire({
            title: 'Warning',
            text: 'NFT must reach level 3 and above for user to mint',
            icon: 'warning',
          });
        }
      });
    } catch (err) {
      this.spinner.hide();
      this.modalService.dismissAll('claim');
      Swal.fire({
        title: 'Ooops...',
        text: 'Something is wrong. Please try again later.',
        icon: 'error',
      });
    }
  }

  onClaimNft() {
    try {
      if (this.claimStatus === true) {
        this.spinner.show();
        var body = {
          address: this.storeAddress,
          tokenId: Number(this.nft.tokenId),
          token_address: this.tokenAdd,
          blockchain: this.blockchain,
        };
      }
    } catch (err) {
      this.spinner.hide();
      this.modalService.dismissAll('claim');
      Swal.fire({
        title: 'Ooops...',
        text: 'Something is wrong. Please try again later.',
        icon: 'error',
      });
    }
  }

  back() {
    window.history.go(-1);
    // this.router.navigate(['product']);
  }
}
