<section style="margin-top: 60px">
  <div class="container">
    <div class="row" *ngIf="nft">
      <div class="col-sm-10 mx-auto">
        <div class="row">
          <div class="col-lg-12">
            <div class="row">
              <div class="col-lg-6 pr-0 pb-0">
                <div class="btn-back cursor-pointer" (click)="back()">
                  <img src="assets/images/btn-back.svg" />BACK
               <br> <br></div>
              </div>
              <div class="col-lg-6 p-0 mb-1">
               <!-- <div class="detail-header" style="color: #fff">
                  {{
                    nft?.contract_address ? "NFT Details" : "ACCESSORY DETAILS"
                  }}
                </div>-->
              </div>
            </div>
          </div>
          <div class="col-lg-12 detail-bg">
            <div class="row">
              <div
                class="col-lg-5 my-auto detail-sub-bg"
                style="padding: 10px; margin-bottom: 10px"
              >
                <div class="row">
                  <div class="col-lg-10 mx-auto">
                    <div
                      class="detail-img"
                      *ngIf="nft.file_type == 'mp4'; else image"
                    >
                      <video
                        width="100%"
                        style="
                          margin-bottom: 15px;
                          object-fit: cover;
                          border-radius: 10px;
                        "
                        autoplay
                        controls
                        loop
                      >
                        <source [src]="nft.media_uri" type="video/mp4" />
                      </video>
                    </div>
                    <ng-template #image>
                      <div class="detail-img">
                        <img
                          [src]="nft?.media_uri"
                          style="border-radius: 10px"
                        />
                      </div>
                    </ng-template>
                  </div>
                </div>

                <!--claim nft modal-->
                <ng-template #claim let-modal>
                  <div class="detail-modal">
                    <div class="modal-body">
                      <div class="row">
                        <div class="col-sm-12 mb-3">
                          <button
                            type="button"
                            class="close"
                            aria-label="Close"
                            (click)="modal.dismiss('Cross click')"
                          >
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                        <div class="col-sm-10 mx-auto">
                          <div
                            class="detail-header ms-0 mb-4 text-center"
                            id="modal-basic-title "
                          >
                            Claim Equipment NFT...
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-sm-12">
                          <div class="row">
                            <div class="col-sm-8 mx-auto">
                              <div>
                                <div class="row">
                                  <div class="col-sm-12 text-center">
                                    <div
                                      class="btn-cancel"
                                      (click)="onClaimCoupon()"
                                    >
                                      <span>{{ claimText }}</span>
                                      <img
                                        *ngIf="claimStatus"
                                        src="../../assets/images/tick.png"
                                      />
                                    </div>
                                  </div>
                                  <div class="col-sm-2"></div>
                                </div>
                                <div class="row">
                                  <div class="col-sm-12 text-center">
                                    <div
                                      class="btn-modal"
                                      (click)="onClaimNft()"
                                    >
                                      <p>Claim NFT</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-template>

                <!-- Connect your Wallet modal -->
                <ng-template #buy let-modal>
                  <div class="detail-modal">
                    <div class="modal-body">
                      <div class="row">
                        <div class="col-sm-12 mb-3">
                          <button
                            type="button"
                            class="close"
                            aria-label="Close"
                            (click)="modal.dismiss('Cross click')"
                          >
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                        <div class="col-sm-9 mx-auto">
                          <div
                            class="detail-header ms-0 mb-4 text-center"
                            id="modal-basic-title "
                          >
                            CHECKOUT
                          </div>
                          <p
                            style="
                              margin-bottom: 20px;
                              text-align: center;
                              color: #ffffff;
                            "
                          >
                            You are about to purchase a Nft.
                          </p>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-sm-12">
                          <button
                            type="button"
                            class="close"
                            aria-label="Close"
                            (click)="modal.dismiss('Cross click')"
                          >
                            <span aria-hidden="true">&times;</span>
                          </button>
                          <div class="row">
                            <div class="col-sm-8 mx-auto">
                              <div class="checkout-price">
                                <div class="row">
                                  <div class="col-sm-7">
                                    <p>Price</p>
                                  </div>
                                  <div
                                    class="col-sm-5"
                                    style="text-align: right"
                                  >
                                    <h4>
                                      {{ nft?.price }}
                                      <span class="price-unit">polygon</span>
                                    </h4>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="row">
                            <div class="col-sm-8 mx-auto">
                              <div class="checkout-desc">
                                <div class="row">
                                  <div class="col-sm-7">
                                    <p>Total Price</p>
                                  </div>
                                  <div class="col-sm-5 text-right">
                                    <h4>
                                      {{ nft?.price }}
                                      <span class="price-unit">polygon</span>
                                    </h4>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="text-center" style="padding: 30px 20px">
                            <div class="row col-lg-10 mx-auto">
                              <div class="col-sm-6">
                                <div
                                  class="btn-cancel"
                                  (click)="modal.dismiss('Cross click')"
                                >
                                  CANCEL
                                </div>
                              </div>
                              <div class="col-sm-6">
                                <div class="btn-modal" (click)="buyNft()">
                                  Buy
                                </div>
                              </div>

                              <!-- install Wallet modal -->
                              <ng-template #complete let-modal>
                                <div class="detail-modal">
                                  <div class="modal-body">
                                    <div class="row">
                                      <div class="col-sm-12">
                                        <button
                                          type="button"
                                          class="close"
                                          aria-label="Close"
                                          (click)="modal.dismiss('Cross click')"
                                        >
                                          <span aria-hidden="true"
                                            >&times;</span
                                          >
                                        </button>
                                        <div style="padding: 30px 20px">
                                          <img
                                            src="assets/images/complete-icon.svg"
                                            height="80"
                                            style="
                                              text-align: center;
                                              display: block;
                                              margin: 20px auto;
                                            "
                                          />
                                          <h4
                                            style="
                                              margin-bottom: 20px;
                                              color: #fbb03b;
                                            "
                                            class="detail-header ms-0 d-block text-center"
                                            id="modal-basic-title"
                                          >
                                            Complete!
                                          </h4>
                                          <p class="text-center">
                                            The transaction is completed.<br />
                                            Please check your inventory.
                                          </p>
                                          <div class="row">
                                            <div class="col-sm-8 mx-auto">
                                              <div class="complete-desc">
                                                <div class="row">
                                                  <div class="col-sm-4">
                                                    <p>Date & Time</p>
                                                  </div>
                                                  <div
                                                    class="col-sm-8 text-right"
                                                  >
                                                    <p>
                                                      {{
                                                        nft?.created_at
                                                          | date
                                                            : "d MMMM yyyy hh:mm a"
                                                      }}
                                                    </p>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div class="row col-lg-10 mx-auto">
                                            <div class="col-12">
                                              <div
                                                class="login-btn"
                                                (click)="
                                                  modal.dismiss('Cross click')
                                                "
                                              >
                                                Close
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </ng-template>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-template>
                <ng-template #sell let-modal>
                  <div class="detail-modal">
                    <div class="modal-body">
                      <div class="row">
                        <div class="col-sm-12 mb-3">
                          <button
                            type="button"
                            class="close"
                            aria-label="Close"
                            (click)="modal.dismiss('Cross click')"
                          >
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                        <div class="col-sm-9 mx-auto">
                          <div
                            class="detail-header ms-0 mb-4 text-center"
                            id="modal-basic-title "
                          >
                            List item for sale
                          </div>
                          <p style="margin-bottom: 20px; text-align: center">
                            {{ this.nft.animal_name }}
                          </p>
                          <div *ngIf="nft.file_type == 'mp4'; else sellImage">
                            <video
                              width="80%"
                              style="
                                margin: auto;
                                margin-bottom: 15px;
                                object-fit: cover;
                                border-radius: 15px;
                                display: block;
                              "
                              controls
                            >
                              <source
                                [src]="nft.media_uri"
                                type="video/mp4"
                                preload="none"
                              />
                            </video>
                          </div>
                          <ng-template #sellImage>
                            <div
                              class="detail-img"
                              style="
                                margin: auto;
                                margin-bottom: 15px;
                                object-fit: cover;
                                border-radius: 15px;
                                display: block;
                                width: 80%;
                              "
                            >
                              <img [src]="nft?.media_uri" />
                            </div>
                          </ng-template>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-sm-12">
                          <button
                            type="button"
                            class="close"
                            aria-label="Close"
                            (click)="modal.dismiss('Cross click')"
                          >
                            <span aria-hidden="true">&times;</span>
                          </button>
                          <div class="row">
                            <div class="col-sm-8 mx-auto">
                              <div class="checkout-price">
                                <div class="row">
                                  <div class="col-sm-3 my-auto">
                                    <p>Price</p>
                                  </div>
                                  <div class="col-sm-9 my-auto">
                                    <input
                                      type="number"
                                      name="sellPrice"
                                      class="setting-input"
                                      [ngModel]="sellPrice"
                                      #sellAmount
                                      width="50%"
                                    />
                                    <h4>
                                      <span
                                        class="price-unit"
                                        style="font-weight: 550"
                                        >polygon</span
                                      >
                                    </h4>
                                    <!-- <p *ngIf="sellAmountInvalid">
                                        {{ sellAmountErrMsg }}
                                      </p> -->
                                  </div>
                                </div>
                                <div
                                  class="row text-center"
                                  *ngIf="sellAmountInvalid"
                                >
                                  <p style="color: red">
                                    {{ sellAmountErrMsg }}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="text-center" style="padding: 10px 20px">
                            <div class="row col-lg-10 mx-auto">
                              <div class="col-sm-6">
                                <div
                                  class="btn-cancel"
                                  (click)="modal.dismiss('Cross click')"
                                >
                                  CANCEL
                                </div>
                              </div>
                              <div class="col-sm-6">
                                <div
                                  class="btn-modal"
                                  (click)="sellToMarket(sellAmount.value)"
                                >
                                  CHECK OUT
                                </div>
                              </div>

                              <!-- install Wallet modal -->
                              <ng-template #complete let-modal>
                                <div class="detail-modal">
                                  <div class="modal-body">
                                    <div class="row">
                                      <div class="col-sm-8 mx-auto">
                                        <button
                                          type="button"
                                          class="close"
                                          aria-label="Close"
                                          (click)="modal.dismiss('Cross click')"
                                        >
                                          <span aria-hidden="true"
                                            >&times;</span
                                          >
                                        </button>
                                        <div style="padding: 30px 20px">
                                          <img
                                            src="assets/images/complete-icon.svg"
                                            height="80"
                                            style="
                                              text-align: center;
                                              display: block;
                                              margin: 20px auto;
                                            "
                                          />
                                          <h4
                                            style="
                                              margin-bottom: 20px;
                                              color: #fbb03b;
                                            "
                                            class="detail-header ms-0 d-block text-center"
                                            id="modal-basic-title"
                                          >
                                            Complete!
                                          </h4>
                                          <p class="text-center">
                                            The transaction is completed.<br />
                                            Please check your inventory.
                                          </p>
                                          <div class="row">
                                            <div class="col-sm-8 mx-auto">
                                              <div class="complete-desc">
                                                <div class="row">
                                                  <div class="col-sm-4">
                                                    <p>Date & Time</p>
                                                  </div>
                                                  <div
                                                    class="col-sm-8 text-right"
                                                  >
                                                    <p>
                                                      {{
                                                        nft?.created_at
                                                          | date
                                                            : "d MMMM yyyy hh:mm a"
                                                      }}
                                                    </p>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div class="row col-lg-10 mx-auto">
                                            <div class="col-12">
                                              <div
                                                class="login-btn"
                                                (click)="
                                                  modal.dismiss('Cross click')
                                                "
                                              >
                                                Close
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </ng-template>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-template>
                <ng-template #approval let-modal>
                  <div class="detail-modal">
                    <div class="modal-body">
                      <div class="row">
                        <div class="col-sm-12 mb-3">
                          <button
                            type="button"
                            class="close"
                            aria-label="Close"
                            (click)="modal.dismiss('Cross click')"
                          >
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                        <div class="col-sm-9 mx-auto">
                          <div
                            class="detail-header ms-0 mb-4 text-center"
                            id="modal-basic-title "
                          >
                            Require Approval
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-sm-12">
                          <button
                            type="button"
                            class="close"
                            aria-label="Close"
                            (click)="modal.dismiss('Cross click')"
                          >
                            <span aria-hidden="true">&times;</span>
                          </button>
                          <div class="row">
                            <div class="col-sm-8 mx-auto">
                              <div class="checkout-price">
                                <div class="row text-center">
                                  <p>Access approval is required.</p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="text-center" style="padding: 10px 20px">
                            <div class="row">
                              <div class="col-sm-6">
                                <div
                                  class="btn-cancel"
                                  (click)="modal.dismiss('Cross click')"
                                >
                                  CANCEL
                                </div>
                              </div>
                              <div class="col-sm-6">
                                <div class="btn-modal" (click)="setApproval()">
                                  Approval
                                </div>
                              </div>

                              <!-- install Wallet modal -->
                              <ng-template #complete let-modal>
                                <div class="detail-modal">
                                  <div class="modal-body">
                                    <div class="row">
                                      <div class="col-sm-8 mx-auto">
                                        <button
                                          type="button"
                                          class="close"
                                          aria-label="Close"
                                          (click)="modal.dismiss('Cross click')"
                                        >
                                          <span aria-hidden="true"
                                            >&times;</span
                                          >
                                        </button>
                                        <div style="padding: 30px 20px">
                                          <img
                                            src="assets/images/complete-icon.svg"
                                            height="80"
                                            style="
                                              text-align: center;
                                              display: block;
                                              margin: 20px auto;
                                            "
                                          />
                                          <h4
                                            style="
                                              margin-bottom: 20px;
                                              color: #fbb03b;
                                            "
                                            class="detail-header ms-0 d-block text-center"
                                            id="modal-basic-title"
                                          >
                                            Complete!
                                          </h4>
                                          <p class="text-center">
                                            The transaction is completed.<br />
                                            Please check your inventory.
                                          </p>
                                          <div class="row">
                                            <div class="col-sm-8 mx-auto">
                                              <div class="complete-desc">
                                                <div class="row">
                                                  <div class="col-sm-4">
                                                    <p>Date & Time</p>
                                                  </div>
                                                  <div
                                                    class="col-sm-8 text-right"
                                                  >
                                                    <p>
                                                      {{
                                                        nft?.created_at
                                                          | date
                                                            : "d MMMM yyyy hh:mm a"
                                                      }}
                                                    </p>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div class="row col-lg-10 mx-auto">
                                            <div class="col-12">
                                              <div
                                                class="login-btn"
                                                (click)="
                                                  modal.dismiss('Cross click')
                                                "
                                              >
                                                Close
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </ng-template>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-template>
                <ng-template #delist let-modal>
                  <div class="detail-modal">
                    <div class="modal-body">
                      <div class="row">
                        <div class="col-sm-12 mb-3">
                          <button
                            type="button"
                            class="close"
                            aria-label="Close"
                            (click)="modal.dismiss('Cross click')"
                          >
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                        <div class="col-sm-9 mx-auto">
                          <div
                            class="detail-header ms-0 mb-4 text-center"
                            id="modal-basic-title "
                          >
                            Confirmation
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-sm-12">
                          <button
                            type="button"
                            class="close"
                            aria-label="Close"
                            (click)="modal.dismiss('Cross click')"
                          >
                            <span aria-hidden="true">&times;</span>
                          </button>
                          <div class="row">
                            <div class="col-sm-8 mx-auto">
                              <div class="checkout-price">
                                <div class="row text-center">
                                  <p *ngIf="nft?.isListed">
                                    Confirm delist NFT?
                                  </p>
                                  <p *ngIf="!nft?.isListed">
                                    Confirm list NFT?
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="text-center" style="padding: 10px 20px">
                            <div class="row">
                              <div class="col-sm-6">
                                <div
                                  class="btn-cancel"
                                  (click)="modal.dismiss('Cross click')"
                                >
                                  No
                                </div>
                              </div>
                              <div class="col-sm-6">
                                <div
                                  class="btn-modal"
                                  (click)="
                                    changeListStatus(
                                      nft.token_address,
                                      nft.isListed
                                    )
                                  "
                                >
                                  Yes
                                </div>
                              </div>

                              <!-- install Wallet modal -->
                              <ng-template #complete let-modal>
                                <div class="detail-modal">
                                  <div class="modal-body">
                                    <div class="row">
                                      <div class="col-sm-8 mx-auto">
                                        <button
                                          type="button"
                                          class="close"
                                          aria-label="Close"
                                          (click)="modal.dismiss('Cross click')"
                                        >
                                          <span aria-hidden="true"
                                            >&times;</span
                                          >
                                        </button>
                                        <div style="padding: 30px 20px">
                                          <img
                                            src="assets/images/complete-icon.svg"
                                            height="80"
                                            style="
                                              text-align: center;
                                              display: block;
                                              margin: 20px auto;
                                            "
                                          />
                                          <h4
                                            style="
                                              margin-bottom: 20px;
                                              color: #fbb03b;
                                            "
                                            class="detail-header ms-0 d-block text-center"
                                            id="modal-basic-title"
                                          >
                                            Complete!
                                          </h4>
                                          <p class="text-center">
                                            The transaction is completed.<br />
                                            Please check your inventory.
                                          </p>
                                          <div class="row">
                                            <div class="col-sm-8 mx-auto">
                                              <div class="complete-desc">
                                                <div class="row">
                                                  <div class="col-sm-4">
                                                    <p>Date & Time</p>
                                                  </div>
                                                  <div
                                                    class="col-sm-8 text-right"
                                                  >
                                                    <p>
                                                      {{
                                                        nft?.created_at
                                                          | date
                                                            : "d MMMM yyyy hh:mm a"
                                                      }}
                                                    </p>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div class="row col-lg-10 mx-auto">
                                            <div class="col-12">
                                              <div
                                                class="login-btn"
                                                (click)="
                                                  modal.dismiss('Cross click')
                                                "
                                              >
                                                Close
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </ng-template>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-template>
                <!-- Offer modal -->
                <ng-template #offer let-modal>
                  <div class="detail-modal">
                    <div class="modal-body">
                      <div class="row">
                        <div class="col-sm-12 mb-3">
                          <button
                            type="button"
                            class="close"
                            aria-label="Close"
                            (click)="modal.dismiss('Cross click')"
                          >
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                        <div class="col-sm-9 mx-auto">
                          <div
                            class="detail-header ms-0 mb-4 text-center"
                            id="modal-basic-title "
                          >
                            OFFER YOUR PRICE
                          </div>
                          <p style="margin-bottom: 20px; text-align: center">
                            {{ this.nft.animal_name }}
                          </p>
                          <div *ngIf="nft.file_type == 'mp4'; else sellImage">
                            <video
                              width="80%"
                              style="
                                margin: auto;
                                margin-bottom: 15px;
                                object-fit: cover;
                                border-radius: 15px;
                                display: block;
                              "
                              controls
                            >
                              <source
                                [src]="nft.media_uri"
                                type="video/mp4"
                                preload="none"
                              />
                            </video>
                          </div>
                          <ng-template #sellImage>
                            <div class="detail-img" style="margin-bottom: 15px">
                              <img
                                [src]="nft?.media_uri"
                                style="
                                  border-radius: 15px;
                                  width: 80%;
                                  margin: auto;
                                  margin-bottom: 15px;
                                  object-fit: cover;
                                  display: block;
                                "
                              />
                            </div>
                          </ng-template>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-sm-12">
                          <button
                            type="button"
                            class="close"
                            aria-label="Close"
                            (click)="modal.dismiss('Cross click')"
                          >
                            <span aria-hidden="true">&times;</span>
                          </button>
                          <div class="row">
                            <div class="col-sm-10 mx-auto">
                              <div class="checkout-price">
                                <div class="row">
                                  <div class="col-sm-3 my-auto">
                                    <p style="color: #ffffff">Offer Price</p>
                                  </div>
                                  <div class="col-sm-9 my-auto">
                                    <input
                                      type="number"
                                      name="offerPrice"
                                      class="setting-input"
                                      [ngModel]="currentOffer * 1.1"
                                      #offerAmount
                                      width="50%"
                                    />
                                    <h4>
                                      <span
                                        class="price-unit"
                                        style="font-weight: 550"
                                        >polygon</span
                                      >
                                    </h4>
                                  </div>
                                </div>
                                <div
                                  class="row text-center"
                                  *ngIf="makeOfferAmountInvalid"
                                >
                                  <p
                                    [innerHTML]="makeOfferAmountErrMsg"
                                    style="color: red"
                                  ></p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="text-center" style="padding: 10px 20px">
                            <div class="row col-lg-10 mx-auto">
                              <div class="col-sm-6">
                                <div
                                  class="btn-cancel"
                                  (click)="modal.dismiss('Cross click')"
                                >
                                  CANCEL
                                </div>
                              </div>
                              <div class="col-sm-6">
                                <div
                                  class="btn-modal"
                                  (click)="onMakeOffer(offerAmount.value)"
                                >
                                  OFFER
                                </div>
                              </div>

                              <!-- install Wallet modal -->
                              <ng-template #complete let-modal>
                                <div class="detail-modal">
                                  <div class="modal-body">
                                    <div class="row">
                                      <div class="col-sm-8 mx-auto">
                                        <button
                                          type="button"
                                          class="close"
                                          aria-label="Close"
                                          (click)="modal.dismiss('Cross click')"
                                        >
                                          <span aria-hidden="true"
                                            >&times;</span
                                          >
                                        </button>
                                        <div style="padding: 30px 20px">
                                          <img
                                            src="assets/images/complete-icon.svg"
                                            height="80"
                                            style="
                                              text-align: center;
                                              display: block;
                                              margin: 20px auto;
                                            "
                                          />
                                          <h4
                                            style="
                                              margin-bottom: 20px;
                                              color: #fbb03b;
                                            "
                                            class="detail-header ms-0 d-block text-center"
                                            id="modal-basic-title"
                                          >
                                            Complete!
                                          </h4>
                                          <p class="text-center">
                                            The offer is record.<br />
                                            Please wait for buyer response.
                                          </p>
                                          <div class="row">
                                            <div class="col-sm-8 mx-auto">
                                              <div class="complete-desc">
                                                <div class="row">
                                                  <div class="col-sm-4">
                                                    <p>Date & Time</p>
                                                  </div>
                                                  <div
                                                    class="col-sm-8 text-right"
                                                  >
                                                    <p>
                                                      {{
                                                        nft?.created_at
                                                          | date
                                                            : "d MMMM yyyy hh:mm a"
                                                      }}
                                                    </p>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div class="row col-lg-10 mx-auto">
                                            <div class="col-12">
                                              <div
                                                class="login-btn"
                                                (click)="
                                                  modal.dismiss('Cross click')
                                                "
                                              >
                                                Close
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </ng-template>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-template>
                <ng-template #acceptOffer let-modal>
                  <div class="detail-modal">
                    <div class="modal-body">
                      <div class="row">
                        <div class="col-sm-12 mb-3">
                          <button
                            type="button"
                            class="close"
                            aria-label="Close"
                            (click)="modal.dismiss('Cross click')"
                          >
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                        <div class="col-sm-9 mx-auto">
                          <div
                            class="detail-header ms-0 mb-4 text-center"
                            id="modal-basic-title "
                          >
                            Accept Offer
                          </div>
                          <p style="margin-bottom: 20px">
                            You are about to accept an offer from
                            {{ currentOfferAddress }}
                          </p>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-sm-12">
                          <button
                            type="button"
                            class="close"
                            aria-label="Close"
                            (click)="modal.dismiss('Cross click')"
                          >
                            <span aria-hidden="true">&times;</span>
                          </button>
                          <div class="row">
                            <div class="col-sm-12 mx-auto">
                              <div class="checkout-price">
                                <div class="row">
                                  <div class="col-sm-7 my-auto">
                                    <p>Offer Price</p>
                                  </div>
                                  <div
                                    class="col-sm-5 my-auto"
                                    style="text-align: right"
                                  >
                                    <h4>
                                      {{ currentOffer }}
                                      <span class="price-unit">polygon</span>
                                    </h4>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="text-center" style="padding: 10px 20px">
                            <div class="row col-lg-12 mx-auto">
                              <div class="col-sm-6">
                                <div
                                  class="btn-cancel"
                                  (click)="modal.dismiss('Cross click')"
                                >
                                  CANCEL
                                </div>
                              </div>
                              <div class="col-sm-6">
                                <div
                                  class="btn-modal"
                                  (click)="onAcceptOffer()"
                                >
                                  Accept
                                </div>
                              </div>

                              <!-- install Wallet modal -->
                              <ng-template #complete let-modal>
                                <div class="detail-modal">
                                  <div class="modal-body">
                                    <div class="row">
                                      <div class="col-sm-8 mx-auto">
                                        <button
                                          type="button"
                                          class="close"
                                          aria-label="Close"
                                          (click)="modal.dismiss('Cross click')"
                                        >
                                          <span aria-hidden="true"
                                            >&times;</span
                                          >
                                        </button>
                                        <div style="padding: 30px 20px">
                                          <img
                                            src="assets/images/complete-icon.svg"
                                            height="80"
                                            style="
                                              text-align: center;
                                              display: block;
                                              margin: 20px auto;
                                            "
                                          />
                                          <h4
                                            style="
                                              margin-bottom: 20px;
                                              color: #fbb03b;
                                            "
                                            class="detail-header ms-0 d-block text-center"
                                            id="modal-basic-title"
                                          >
                                            Complete!
                                          </h4>
                                          <p class="text-center">
                                            The offer is accept.
                                          </p>
                                          <div class="row">
                                            <div class="col-sm-8 mx-auto">
                                              <div class="complete-desc">
                                                <div class="row">
                                                  <div class="col-sm-4">
                                                    <p>Date & Time</p>
                                                  </div>
                                                  <div
                                                    class="col-sm-8 text-right"
                                                  >
                                                    <p>
                                                      {{
                                                        nft?.created_at
                                                          | date
                                                            : "d MMMM yyyy hh:mm a"
                                                      }}
                                                    </p>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div class="row col-lg-10 mx-auto">
                                            <div class="col-12">
                                              <div
                                                class="login-btn"
                                                (click)="
                                                  modal.dismiss('Cross click')
                                                "
                                              >
                                                Close
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </ng-template>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </div>
              <div class="col-lg-7">
                <!-- animal nft details -->
                <div
                  class="detail-sub-bg mb-3"
                  style="
                    background: none;
                    padding: 15px 30px;
                    border-radius: 15px;
                  "
                  *ngIf="nft.contract_address"
                >
                  <div class="row mb-3">
                    <div class="col-lg-6">
                      <div
                        style="
                          text-transform: uppercase;
                          font-family: 'Poppins';
						  color: #ffffff;
                        "
                      >
                        owner:
                        <span class="detail-owner-text" style="color: #ffffff"
                          >{{
                            nft.owner_address?.length >= 10
                              ? (nft.owner_address | slice : 0 : 4) +
                                "..." +
                                nft.owner_address.slice(
                                  nft.owner_address.length - 4
                                )
                              : "-"
                          }}
                        </span>
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div
                        style="
                          text-transform: uppercase;
                          font-family: 'Poppins';
						  color: #ffffff;
                        "
                      >
                        Meta Legend ID:
                        <span class="detail-owner-text" style="color: #ffffff"
                          >#{{ this.nft.tokenId }}</span
                        >
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-6">
                      <div class="d-sm-flex">
                        <p class="detail-name-text" style="font-size: 24px">
                          {{ nft.animal_name }}
                        </p>
                        <!-- <p
                          class="detail-lv"
                          style="background: transparent; color: #ffb100"
                        >
                          Lv: {{ nft.animal_attributes[0].value }}
                        </p> -->
                      </div>
                    </div>
                    <div class="col-lg-6">
                     <!-- <div class="d-flex">
                        <img
                          src="assets/images/huntar-icon.svg"
                          width="40"
                          class="me-2"
                        />
                        <p class="detail-name-text" style="font-size: 24px">
                          {{ nft.animal_attributes[1].value }}
                        </p>
                      </div>-->
                    </div>
                  </div>
                </div>

                <!-- game nft details -->
                <div
                  class="detail-sub-bg mb-3"
                  style="
                    background: none;
                    padding: 15px 30px;
                    border-radius: 15px;
                  "
                  *ngIf="!nft.contract_address"
                >
                  <div class="row mb-3">
                    <div class="col-lg-6">
                      <div
                        style="
                          text-transform: uppercase;
                          font-family: 'Poppins';
						  color: #ffffff;
                        "
                      >
                        owner:
                        <span class="detail-owner-text" style="color: #ffffff"
                          >{{
                            nft.owner_address?.length >= 10
                              ? (nft.owner_address | slice : 0 : 4) +
                                "..." +
                                nft.owner_address.slice(
                                  nft.owner_address.length - 4
                                )
                              : "-"
                          }}
                        </span>
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div
                        style="
                          text-transform: uppercase;
                          font-family: 'Poppins';
						  color: #ffffff;
                        "
                      >
                        Meta Legend ID:
                        <span
                          class="detail-owner-text"
                          style="
                            color: #ffffff;
                            font-family: 'Poppins', sans-serif;
                          "
                          >#{{ this.nft.tokenId }}</span
                        >
                      </div>
                      <div
                        style="
                          text-transform: uppercase;
                          font-family: 'Poppins';
						  color: #ffffff;
						  font-size: 12px;
                        "
                      >
                        posted on:
                        <span
                          class="detail-owner-text"
                          style="
                            color: #ffffff;
                            font-family: 'Poppins', sans-serif;
                          "
                          >{{ this.nft.created_at | date : "longDate" }}</span
                        >
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-12 text-center">
                      <div>
                        <p class="detail-name-text">
                          {{
                            nft?.contract_address
                              ? nft.animal_name
                              : nft.attributes[2].value
                          }}
                        </p>
                      </div>
                      <div>
                        <p class="detail-lv">
                          Lv:
                          {{ nft?.attributes ? nft.attributes[0].value : "0" }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="detail-card-bg">
                  <div class="product-tabs">
                    <div class="product-tabs__list">
                      <a
                        href="#tab-lifeInfo"
                        class="product-tabs__item"
                        [ngClass]="{
                          'product-tabs__item--active': tab === 'lifeInfo'
                        }"
                        (click)="$event.preventDefault(); tab = 'lifeInfo'"
                        >Info</a
                      >
                      <a
                        href="#tab-lifeInfo"
                        class="product-tabs__item"
                        [ngClass]="{
                          'product-tabs__item--active': tab === 'stats'
                        }"
                        (click)="$event.preventDefault(); tab = 'stats'"
                        >stats</a
                      >
                    </div>
                    <div class="product-tabs__content">
                      <div
                        class="product-tabs__pane"
                        id="tab-lifeInfo"
                        [ngClass]="{
                          'product-tabs__pane--active': tab === 'lifeInfo'
                        }"
                      >
                        <!-- annimal nft info -->
                        <div class="row" *ngIf="nft?.contract_address">
                          <div class="col-lg-12 mb-3">
                            <div
                              class="row"
                              *ngFor="let attribute of nft?.animal_attributes"
                            >
                              <div class="col-sm-7" style="font-size: 16px; font-weight: 400; line-height: 40px; color: #ffffff;">
                                {{ attribute.trait_type }}:
                              </div>
                              <div
                                class="col-sm-5 text-right"
                                style="font-size: 16px; font-weight: 400; line-height: 30px; color: #ffffff;"
                              >
                                {{ attribute.value }}
                              </div>
                            </div>
                          </div>
                        </div>
                        <!-- game nft info -->
                        <div
                          class="row"
                          *ngIf="
                            nft?.token_address ===
                            '0xcea34fa634f6991dcf746047855d06b7326b979b'
                          "
                        >
                          <div class="col-lg-12 mb-3">
                            <div class="detail-tab-title">
                              {{ nft?.attributes[1]?.trait_type }}:
                              {{ nft?.attributes[1]?.value }}
                            </div>
                            <div class="detail-tab-title">
                              {{ nft?.attributes[2].trait_type }}:
                              {{ nft?.attributes[2].value }}
                            </div>
                          </div>
                          <div class="col-sm-12">
                            <div class="row">
                              <div
                                class="col-sm-6 mb-2 px-2"
                                *ngFor="
                                  let attribute of nft?.attributes.slice(3)
                                "
                              >
                                <div
                                  class="d-flex align-items-center"
                                  style="font-size: 13px; line-height: 13px; color: #ffffff;"
                                >
                                  <img
                                    *ngIf="
                                      attribute?.trait_type !==
                                      'Regenerate HP/s'
                                    "
                                    src="assets/icon/{{
                                      attribute?.trait_type +
                                        '_' +
                                        attribute?.display_type
                                    }}.png"
                                    style="border-radius: 5px;"
                                    width="45"
                                    height="45"
                                    class="me-3"
									
                                  />
                                  <img
                                    *ngIf="
                                      attribute?.trait_type == 'Regenerate HP/s'
                                    "
                                    src="assets/icon/Regenerate HPs_boost_percentage.png"
                                    style="border-radius: 5px"
                                    width="45"
                                    height="45"
                                    class="me-3"
                                  />
                                  <div style="font-size: 13px; line-height: 13px; color: #ffffff;">
                                    {{ attribute?.trait_type }}
                                    {{ attribute?.value }}
                                    <span 
                                      *ngIf="
                                        attribute?.display_type ==
                                        'boost_percentage'
                                      "
                                      >%</span
                                    >
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                        class="product-tabs__pane"
                        id="tab-stats"
                        [ngClass]="{
                          'product-tabs__pane--active': tab === 'stats'
                        }"
                      >
                        <div class="row" style="margin-bottom: 20px">
                          <!-- Stats dynamic here -->
                          <div
                            class="col-lg-12 mb-2"
                            *ngFor="let stat of nft.extra_attributes"
                          >
                            <div class="stat-box">
                              <h3>
                                {{ stat.trait_type }}
                                <span>{{ stat.value }}</span>
                              </h3>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                        class="product-tabs__pane"
                        id="tab-land"
                        [ngClass]="{
                          'product-tabs__pane--active': tab === 'land'
                        }"
                      >
                        <div class="row">
                          <div class="col-lg-7 my-auto">
                            <img src="assets/images/land.svg" width="100%" />
                          </div>
                          <div class="col-lg-5">
                            <div class="row py-2">
                              <div class="col-l2">
                                <div class="unknown-label">Unknown</div>
                              </div>
                              <div class="col-5">
                                <img
                                  src="assets/images/energy-icon.svg"
                                  class="land-lv-icon"
                                />
                                hill
                              </div>
                              <div class="col-7 mt-auto">
                                <div class="land-lv f-400 text-right">
                                  Lv: 1
                                </div>
                              </div>
                            </div>
                            <div class="row py-2">
                              <div class="col-l2">
                                <div class="detail-tab-title">
                                  Energy/Minutes :
                                </div>
                              </div>
                              <div class="col-3">
                                <img
                                  src="assets/images/energy-icon.svg"
                                  class="land-icon"
                                />
                              </div>
                              <div class="col-9 mt-auto">
                                <div class="land-ability text-right">0.4/M</div>
                              </div>
                            </div>
                            <div class="row py-2">
                              <div class="col-l2">
                                <div class="detail-tab-title">Life Stats :</div>
                              </div>
                              <div class="col-3">
                                <img
                                  src="assets/images/life-stats.svg"
                                  class="land-icon"
                                />
                              </div>
                              <div class="col-9 mt-auto">
                                <div class="land-ability text-right">
                                  Win + 1
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                        class="product-tabs__pane"
                        id="tab-fusion"
                        [ngClass]="{
                          'product-tabs__pane--active': tab === 'fusion'
                        }"
                      >
                        <div class="row">
                          <div class="col-lg-12 my-auto">
                            <p
                              style="
                                color: #845636;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                              "
                            >
                              Coming Soon...
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <ng-container *ngIf="nft?.price > 0">
                    <ng-container *ngIf="currentOffer > 0">
                      <div class="row">
                        <div class="col-lg-8 mx-auto text-center">
                          <b>Highest Offer</b>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-lg-8 mx-auto">
                          <div class="detail-btn-price">
                            <!--<img src="assets/images/price-unit.svg" />-->
                            <div class="font-family">
                              {{ currentOffer }} polygon
                              <span>({{ currentOffer }} USD)</span>
                              <span
                                *ngIf="storeAddress === currentOfferAddress"
                                class="text-red"
                                >(Yours)</span
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                    </ng-container>
					
					 <div class="row" *ngIf="nft?.price > 0 && nft?.isListed">
                    <div class="col-lg-8 mx-auto">
                      <div class="detail-btn-price" style="border: 0;">
					  <p style="font-size: 18px; font-weight: 600px; padding-right: 80px;">Price: </p>
                       <!--<img src="assets/images/price-unit.svg" />-->
                        <div>
                          {{ nft?.price }} polygon
                          <span>({{ nft?.price }} USD)</span>
                        </div>
                      </div>
                    </div>
                  </div>

                    <div
                      class="row"
                      *ngIf="
                        nft.owner_address !== storeAddress && nft?.isListed;
                        else ownerOffer
                      "
                    >
                      <div class="col-lg-8 mx-auto">
                        <div
                          class="btn-buy cursor-pointer"
                          (click)="open(offer, 'lg')"
                        >
                          <div class="d-block font-family">Offer now</div>
                        </div>
                      </div>
                    </div>

                    <ng-template #ownerOffer>
                      <div class="row" *ngIf="currentOffer !== 0">
                        <div class="col-lg-8 mx-auto">
                          <div
                            class="btn-buy cursor-pointer"
                            (click)="open(acceptOffer, 'lg')"
                          >
                            <div class="d-block font-family">Accept offer</div>
                          </div>
                        </div>
                      </div>
                    </ng-template>
                  </ng-container>
                  <!--<div class="row" *ngIf="nft?.price > 0 && nft?.isListed">
                    <div class="col-lg-8 mx-auto">
                      <div class="detail-btn-price">
                       <img src="assets/images/price-unit.svg" />
                        <div>
                          {{ nft?.price }} polygon
                          <span>({{ nft?.price }} USD)</span>
                        </div>
                      </div>
                    </div>
                  </div>-->
                  <div
                    class="row"
                    *ngIf="
                      nft.owner_address !== storeAddress &&
                      nft.price > 0 &&
                      nft?.isListed
                    "
                  >
                    <div class="col-lg-8 mx-auto">
                      <div
                        class="btn-buy cursor-pointer"
                        (click)="open(buy, 'lg')"
                      >
                        <div class="d-block font-family">Buy now</div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="row"
                    *ngIf="
                      !nft.isAuction &&
                      !nft.isMarketPlace &&
                      storeAddress === nft.owner_address &&
                      !nft?.isListed &&
                      nft?.price === 0
                    "
                  >
                    <div class="col-lg-8 mx-auto">
                      <div
                        class="btn-buy cursor-pointer"
                        (click)="open(sell, 'lg')"
                      >
                        <div class="d-block font-family">Sell</div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="row"
                    *ngIf="
                      !nft.isAuction &&
                      !nft.isMarketPlace &&
                      storeAddress === nft.owner_address &&
                      nft?.isListed &&
                      nft?.price > 0
                    "
                  >
                    <div class="col-lg-8 mx-auto">
                      <div
                        class="btn-buy cursor-pointer"
                        (click)="open(sell, 'lg')"
                      >
                        <div class="d-block font-family">Edit Price</div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="row"
                    *ngIf="
                      !nft.isAuction &&
                      !nft.isMarketPlace &&
                      storeAddress === nft.owner_address &&
                      nft.price > 0
                    "
                  >
                    <div class="col-lg-8 mx-auto">
                      <div
                        class="btn-buy cursor-pointer"
                        (click)="open(delist, 'lg')"
                      >
                        <div
                          class="d-block font-family"
                          *ngIf="nft?.price > 0 && nft?.isListed"
                        >
                          Delist
                        </div>
                        <div
                          class="d-block font-family"
                          *ngIf="nft?.price > 0 && !nft?.isListed"
                        >
                          List
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="row"
                    *ngIf="
                      nft.owner_address !== storeAddress &&
                      offChain === '1' &&
                      nft.price == 0
                    "
                  >
                    <div class="col-lg-8 mx-auto">
                      <div
                        class="btn-buy cursor-pointer"
                        (click)="open(claim, 'ms')"
                      >
                        <div class="d-block font-family">Mint</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<ngx-spinner type="ball-clip-rotate-multiple">
  <p class="loading">Loading Awesomeness...</p>
</ngx-spinner>
