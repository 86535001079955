<section style="margin-top: 60px">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="row">
          <div class="col-lg-5 pr-0 pb-0">
            <div class="btn-back cursor-pointer" (click)="back()">
              <img src="assets/images/btn-back.svg" />Back
            </div>
          </div>
          <div class="col-lg-7 p-0">
            <div class="detail-header">Mystery Box Details</div>
          </div>
        </div>
      </div>
      <div class="col-lg-12 detail-bg">
        <div class="row">
          <div
            class="col-lg-5 my-auto"
            style="padding: 30px; margin-bottom: 10px"
          >
            <div class="row">
              <div class="col-lg-12 mx-auto">
                <div class="detail-img" style="background: transparent">
                  <img
                    src="assets/images/mystery-box-1.svg"
                    width="100%"
                    style="margin-bottom: 15px; object-fit: cover"
                  />
                </div>
                <div class="btn-buy cursor-pointer" (click)="open(loading)">
                  <div>Loading</div>
                </div>
                <!-- Connect your Wallet modal -->
                <ng-template #loading let-modal>
                  <div class="detail-modal">
                    <div class="modal-body">
                      <div class="row">
                        <div class="col-sm-12">
                          <button
                            type="button"
                            class="close"
							
                            aria-label="Close"
                            (click)="modal.dismiss('Cross click')"
                          >
                            <span aria-hidden="true">&times;</span>
                          </button>
                          <h2
                            class="modal-title ms-0 mb-2"
                            id="modal-basic-title"
                          >
                            MYSTERY 
                            BOX
                          </h2>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-6 mx-auto">
                          <img
                            src="assets/images/mystery-box-1.svg"
                            width="100%"
                            
                            (click)="open(reward); modal.dismiss('Cross click')"
                          />
                        </div>
                      </div>
                      <!-- Connect your Wallet modal -->
                      <ng-template #reward let-modal>
                        <div class="detail-modal">
                          <div class="modal-body">
                            <div class="row">
                              <div class="col-sm-12">
                                <button
                                  type="button"
                                  class="close"
								style="position: absolute; top: -5%; left: 100%;"
								  
                                  aria-label="Close"
                                  (click)="modal.dismiss('Cross click')"
                                >
                                  <span aria-hidden="true">&times;</span>
                                </button>

                                <div class="row">
                                  <div class="col-lg-4">
                                    <h2
                                      class="modal-title ms-0 mb-2"
                                      id="modal-basic-title"
                                    >
                                      MYSTERY
                                      BOX
                                    </h2>
                                    <img
                                      src="assets/images/mystery-box-1.svg"
                                      width="100%"
                                      style="
                                        margin-bottom: 35px;
                                        object-fit: cover;
                                      "
                                    />
                                  </div>
                                  <div class="col-lg-7 ms-auto">
                                    <img
                                      src="assets/images/lion.svg"
                                      width="100px"
                                      style="
                                        object-fit: cover;
                                        text-align: center;
                                        margin: auto;
                                        margin-bottom: 35px;
                                        display: block;
                                      "
                                    />
                                    <div class="row">
                                      <div class="col-4">
                                        <img
                                          src="assets/images/card-1.svg"
                                          width="100%"
                                          style="margin-bottom: 15px"
                                        />
                                      </div>
                                      <div class="col-4">
                                        <img
                                          src="assets/images/card-2.svg"
                                          width="100%"
                                          style="margin-bottom: 15px"
                                        />
                                      </div>
                                      <div class="col-4">
                                        <img
                                          src="assets/images/card-3.svg"
                                          width="100%"
                                          style="margin-bottom: 15px"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </ng-template>
                    </div>
                  </div>
                </ng-template>
                <div class="btn-buy cursor-pointer" (click)="open(buy)">
                  <div>Open Now</div>
                </div>
                <!-- Connect your Wallet modal -->
                <ng-template #buy let-modal>
                  <div class="detail-modal">
                    <div class="modal-body">
                      <div class="row">
                        <div class="col-sm-12 text-center">
                          <button
                            type="button"
                            class="close"
							
                            aria-label="Close"
                            (click)="modal.dismiss('Cross click')"
                          >
                            <span aria-hidden="true">&times;</span>
                          </button>
                          <h4
                            class="modal-title ms-0 mb-2"
                            id="modal-basic-title"
                          >
                            CHECKOUT
                          </h4>
                          <p style="margin-bottom: 40px">
                            You are about to purchase a mystery box.
                          </p>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-sm-12">
                          <button
                            type="button"
                            class="close"
                            aria-label="Close"
                            (click)="modal.dismiss('Cross click')"
                          >
                            <span aria-hidden="true">&times;</span>
                          </button>
                          <div class="row">
                            <div class="col-sm-8 mx-auto">
                              <div class="checkout-price">
                                <div class="row">
                                  <div class="col-sm-9">
                                    <p>Price</p>
                                  </div>
                                  <div class="col-sm-3">
                                    <h4>
                                      {{ nft?.price }}
                                      <span class="price-unit">polygon</span>
                                    </h4>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="row">
                            <div class="col-sm-8 mx-auto">
                              <div class="checkout-desc">
                                <div class="row">
                                  <div class="col-sm-9">
                                    <p>Your Balance</p>
                                  </div>
                                  <div class="col-sm-3 text-right">
                                    <h4>
                                      {{ nft?.price }}
                                      <span class="price-unit">polygon</span>
                                    </h4>
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col-sm-9">
                                    <p>Amount</p>
                                  </div>
                                  <div class="col-sm-3 text-right">
                                    <h4>{{ nft?.price }}</h4>
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col-sm-9">
                                    <p>Total Price</p>
                                  </div>
                                  <div class="col-sm-3 text-right">
                                    <h4>
                                      {{ nft?.price }}
                                      <span class="price-unit">polygon</span>
                                    </h4>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="text-center" style="padding: 30px 20px">
                            <div class="row col-lg-10 mx-auto">
                              <div class="col-sm-6">
                                <div
                                  class="btn-cancel"
                                  (click)="modal.dismiss('Cross click')"
                                >
                                  CANCEL
                                </div>
                              </div>
                              <div class="col-sm-6">
                                <div
                                  class="btn-modal"
                                  (click)="
                                    open(complete); modal.dismiss('Cross click')
                                  "
                                >
                                  CHECK OUT
                                </div>
                              </div>

                              <!-- install Wallet modal -->
                              <ng-template #complete let-modal>
                                <div class="detail-modal">
                                  <div class="modal-body">
                                    <div class="row">
                                      <div class="col-sm-12">
                                        <button
                                          type="button"
                                          class="close"
                                          aria-label="Close"
                                          (click)="modal.dismiss('Cross click')"
                                        >
                                          <span aria-hidden="true"
                                            >&times;</span
                                          >
                                        </button>
                                        <div style="padding: 30px 20px">
                                          <img
                                            src="assets/images/complete-icon.svg"
                                            height="80"
                                            style="
                                              text-align: center;
                                              display: block;
                                              margin: 20px auto;
                                            "
                                          />
                                          <h4
                                            style="
                                              margin-bottom: 20px;
                                              color: #9e29c1;
                                            "
                                            class="modal-title ms-0 d-block text-center"
                                            id="modal-basic-title"
                                          >
                                            Complete!
                                          </h4>
                                          <p class="text-center">
                                            The transaction is completed.<br />
                                            Please check your inventory.
                                          </p>
                                          <div class="row">
                                            <div class="col-sm-8 mx-auto">
                                              <div class="complete-desc">
                                                <div class="row">
                                                  <div class="col-sm-4">
                                                    <p>Date & Time</p>
                                                  </div>
                                                  <div
                                                    class="col-sm-8 text-right"
                                                  >
                                                    <p>
                                                      {{
                                                        nft?.created_at
                                                          | date
                                                            : "d MMMM yyyy hh:mm a"
                                                      }}
                                                    </p>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div class="row col-lg-10 mx-auto">
                                            <div class="col-12">
                                              <div
                                                class="login-btn"
                                                (click)="
                                                  modal.dismiss('Cross click')
                                                "
                                              >
                                                Close
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </ng-template>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </div>
            </div>
          </div>
          <div class="col-lg-7">
            <div class="row">
              <div class="col-12">
                <div class="mystery-box-detail-header">Drop rate detail</div>
              </div>
              <div class="col-12 mystery-box-detail-bg mb-3">
                <div class="row">
                  <div class="col-6 col-sm-3 mystery-box-detail">
                    <div class="row py-2">
                      <div class="col-lg-7">
                        <p class="mystery-box-detail-content">MTL Life</p>
                      </div>
                      <div class="col-sm-5">
                        <p class="mystery-box-detail-percentage">30%</p>
                      </div>
                    </div>
                    <div class="row py-2">
                      <div class="col-lg-7">
                        <p class="mystery-box-detail-content">MTL Land</p>
                      </div>
                      <div class="col-sm-5">
                        <p class="mystery-box-detail-percentage">30%</p>
                      </div>
                    </div>
                    <div class="row py-2">
                      <div class="col-lg-7">
                        <p class="mystery-box-detail-content">Skill Card</p>
                      </div>
                      <div class="col-sm-5">
                        <p class="mystery-box-detail-percentage">30%</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-6 col-sm-3 mystery-box-detail">
                    <div class="row py-2">
                      <div class="col-lg-7">
                        <p class="mystery-box-detail-content">MTL Life</p>
                      </div>
                      <div class="col-sm-5">
                        <p class="mystery-box-detail-percentage text-mature">
                          30%
                        </p>
                      </div>
                    </div>
                    <div class="row py-2">
                      <div class="col-lg-7">
                        <p class="mystery-box-detail-content">MTL Land</p>
                      </div>
                      <div class="col-sm-5">
                        <p class="mystery-box-detail-percentage text-mature">
                          30%
                        </p>
                      </div>
                    </div>
                    <div class="row py-2">
                      <div class="col-lg-7">
                        <p class="mystery-box-detail-content">Skill Card</p>
                      </div>
                      <div class="col-sm-5">
                        <p class="mystery-box-detail-percentage text-mature">
                          30%
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-6 col-sm-3 mystery-box-detail">
                    <div class="row py-2">
                      <div class="col-lg-7">
                        <p class="mystery-box-detail-content">MTL Life</p>
                      </div>
                      <div class="col-sm-5">
                        <p class="mystery-box-detail-percentage text-rouge">
                          30%
                        </p>
                      </div>
                    </div>
                    <div class="row py-2">
                      <div class="col-lg-7">
                        <p class="mystery-box-detail-content">MTL Land</p>
                      </div>
                      <div class="col-sm-5">
                        <p class="mystery-box-detail-percentage text-rouge">
                          30%
                        </p>
                      </div>
                    </div>
                    <div class="row py-2">
                      <div class="col-lg-7">
                        <p class="mystery-box-detail-content">Skill Card</p>
                      </div>
                      <div class="col-sm-5">
                        <p class="mystery-box-detail-percentage text-rouge">
                          30%
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-6 col-sm-3 mystery-box-detail">
                    <div class="row py-2">
                      <div class="col-lg-7">
                        <p class="mystery-box-detail-content">MTL Life</p>
                      </div>
                      <div class="col-sm-5">
                        <p class="mystery-box-detail-percentage text-majestic">
                          30%
                        </p>
                      </div>
                    </div>
                    <div class="row py-2">
                      <div class="col-lg-7">
                        <p class="mystery-box-detail-content">MTL Land</p>
                      </div>
                      <div class="col-sm-5">
                        <p class="mystery-box-detail-percentage text-majestic">
                          30%
                        </p>
                      </div>
                    </div>
                    <div class="row py-2">
                      <div class="col-lg-7">
                        <p class="mystery-box-detail-content">Skill Card</p>
                      </div>
                      <div class="col-sm-5">
                        <p class="mystery-box-detail-percentage text-majestic">
                          30%
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 mb-3">
                <div class="row mystery-box-detail">
                  <div class="col-12 text-center mb-3">
                    <p style="font-size: 20">
                      Available MTL life:
                      <span class="available-amount">12</span>
                    </p>
                  </div>
                  <div class="col-12 text-center">
                    <div class="row">
                      <div class="col-4 col-sm-3 col-lg-2">
                        <div class="avatar-box"></div>
                      </div>
                      <div class="col-4 col-sm-3 col-lg-2">
                        <div class="avatar-box"></div>
                      </div>
                      <div class="col-4 col-sm-3 col-lg-2">
                        <div class="avatar-box"></div>
                      </div>
                      <div class="col-4 col-sm-3 col-lg-2">
                        <div class="avatar-box"></div>
                      </div>
                      <div class="col-4 col-sm-3 col-lg-2">
                        <div class="avatar-box"></div>
                      </div>
                      <div class="col-4 col-sm-3 col-lg-2">
                        <div class="avatar-box"></div>
                      </div>
                      <div class="col-4 col-sm-3 col-lg-2">
                        <div class="avatar-box"></div>
                      </div>
                      <div class="col-4 col-sm-3 col-lg-2">
                        <div class="avatar-box"></div>
                      </div>
                      <div class="col-4 col-sm-3 col-lg-2">
                        <div class="avatar-box"></div>
                      </div>
                      <div class="col-4 col-sm-3 col-lg-2">
                        <div class="avatar-box"></div>
                      </div>
                      <div class="col-4 col-sm-3 col-lg-2">
                        <div class="avatar-box"></div>
                      </div>
                      <div class="col-4 col-sm-3 col-lg-2">
                        <div class="avatar-box"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
